import { Comment } from '@netcurio/frontend-common'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { CommentsInterface } from '../../../common/interfaces/comments.inteface'
import { useClient } from '../../../hooks/useClient'
import { useUpdateEffect } from '../../../hooks/useUpdateEffect'
import { CREATE_PURCHASE_REQUISITION_COMMENT } from '../graphql-ops/mutations'
import { GET_PURCHASE_REQUISITION_COMMENTS } from '../graphql-ops/queries'

interface Props {
	initialComments?: CommentsInterface[]
	purchaseRequisitionId: number | undefined
	customerRfc: string
}

const transformComments = (comment: any): Comment => ({
	...comment,
	created_by: comment.createdBy
})

export const usePurchaseRequisitionComments = ({
	initialComments,
	purchaseRequisitionId: id,
	customerRfc
}: Props) => {
	const client = useClient()
	const [comments, setComments] = useState<Comment[]>(initialComments?.map(transformComments) ?? [])

	const { mutate: addComment } = useMutation({
		mutationFn: async (comment: string) => {
			const { data } = await client.mutate({
				mutation: CREATE_PURCHASE_REQUISITION_COMMENT,
				variables: {
					id: id,
					customerRfc: customerRfc,
					comment: comment
				}
			})
			return data
		},
		onSuccess: () => {
			refetchComments()
		},
		onError: (error) => {
			console.error('Error adding comment:', error)
		}
	})

	const { refetch: refetchComments } = useQuery({
		queryKey: ['fetchComments', id, customerRfc],
		queryFn: async () => {
			if (id) {
				const { data } = await client.query({
					query: GET_PURCHASE_REQUISITION_COMMENTS,
					variables: {
						id: id,
						customerRfc: customerRfc
					},
					fetchPolicy: 'no-cache'
				})
				setComments(data.PurchaseRequisition?.comments?.map(transformComments))
			}
			return []
		}
	})

	useUpdateEffect(() => {
		setComments(initialComments?.map(transformComments) ?? [])
	}, [initialComments])

	return {
		comments,
		addComment,
		refetchComments
	}
}
