import {
	Comment,
	currencyFormatter,
	dateFormatter,
	getCurrentUser,
	getSerieFolio,
	PURCHASE,
	Roles,
	Status,
	URLS,
	User
} from '@netcurio/frontend-common'
import {
	CommentSectionSize,
	CommentSectionVariant,
	CommentsSection,
	FileViewerModal,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	StatusBadges,
	TotalContainerBar,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AttachDocumentsModal } from '../../../components/AttachDocumentsModal/AttachDocumentsModal'
import { AttachedDocumentsModal } from '../../../components/AttachedDocumentsModal/AttachedDocumentsModal'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { useEnvironment } from '../../../hooks/useEnvironment'
import {
	Consumption,
	ErrorWrapper,
	Invoice,
	PaymentComplement,
	PaymentComplementShape,
	PurchaseOrder,
	RelatedAdvance
} from '../../../types'
import { InvoiceValidations, Validations } from '../../../types/Validations'
import { connection } from '../../../utilities/connection'
import Constants from '../../../utilities/constants'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import { downloadXMLFile } from '../../../utilities/file-handling/download-xml-file'
import Formatter from '../../../utilities/formatter'
import { getUrlParameter } from '../../../utilities/getUrlParameter'
import { getUserRolesForCompany } from '../../../utilities/getUserRolesForCompany'
import { redirectByStatus } from '../../../utilities/validateUrlRoles'
import * as graphqlRequest from '../graphql'
import { getVisualStatusInvoiceDetail } from '../helpers/visualStatusHelperInvoiceDetail'
import { CancelInvoice } from '../InvoiceActions/CancelInvoice'
import { SendInvoice } from '../InvoiceActions/SendInvoice'
import { AssociationSection } from './AssociationSection/AssociationSection'
import { DetailField } from './DetailField/DetailField'
import { InvoiceAlert } from './InvoiceAlert/InvoiceAlert'
import styles from './InvoiceDetail.module.scss'
import { InvoiceDetailButtons } from './InvoiceDetailButtons/InvoiceDetailButtons'
import { InvoiceInfoBar } from './InvoiceInfoBar/InvoiceInfoBar'
import { InvoiceItemList } from './InvoiceItemList/InvoiceItemList'
import { ManuallyAuthMatchModal } from './ManuallyAuthInvoice/ManuallyAuthMatchModal/ManuallyAuthMatchModal'
import { ManuallyAuthModal } from './ManuallyAuthInvoice/ManuallyAuthModal/ManuallyAuthModal'
import { ManuallyAuthInvoiceSummaryModal } from './ManuallyAuthInvoice/ManuallyAuthSummaryModal/ManuallyAuthSummaryModal'
import { StandaloneDocumentManuallyAuthModal } from './ManuallyAuthInvoice/StandaloneDocumentManuallyAuthModal/StandaloneDocumentManuallyAuthModal'
import { ErrorModal } from './Modals/ErrorModal/ErrorModal'
import { RegisterPaymentModal } from './Modals/RegisterPaymentModal/RegisterPaymentModal'
import { RejectPendingMessageInvoiceDetail } from './Modals/RejectPendingMessageInvoiceDetail/RejectPendingMessageInvoiceDetail'
import { PaymentComplementInfo } from './PaymentComplementInfo/PaymentComplementInfo'
import { PaymentComplementInfoTable } from './PaymentComplementInfo/PaymentComplementInfoTable'
import { RelatedAdvances } from './RelatedAdvances/RelatedAdvances'
import {
	defaultFileViewer,
	defaultPaymentSelected,
	defaultValuesInvoice,
	defaultValuesValidations
} from './utilities/defaultValues'

interface PaymentComplementButton {
	show: boolean
	disable: boolean
}

const isStatusInArray = (status: string, array: string[]): boolean => {
	return array.includes(status)
}

const getIsRelatedToAdvance = (incomingInvoice: Invoice): boolean => {
	return (
		(incomingInvoice?.related_advances &&
			incomingInvoice?.related_advances.length > 0 &&
			incomingInvoice?.related_advances.some((relatedAdvance: RelatedAdvance) => {
				return relatedAdvance.type === '07'
			})) ??
		false
	)
}

export const InvoiceDetail = (): ReactElement => {
	const { t } = useTranslation()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const history = useHistory()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const [errorMessage, setErrorMessage] = useState<ErrorWrapper | undefined>()
	const [comments, setComments] = useState<Comment[]>([])
	const [isCommentsSectionExtended, setIsCommentsSectionExtended] = useState<boolean>(false)
	const [paymentDate, setPaymentDate] = useState('NA')
	const [bankReference, setBankReference] = useState('NA')
	const [isCustomer] = useState(getUserRolesForCompany().includes(Roles.CUSTOMER))
	const [fileViewer, setFileViewer] = useState(defaultFileViewer)
	const [invoice, setInvoice] = useState<Invoice>(defaultValuesInvoice)
	const [paymentSelected, setPaymentSelected] = useState<PaymentComplement>(defaultPaymentSelected)
	const [registerPaymentModal, setRegisterPaymentModal] = useState<boolean>(false)
	const [cancelInvoiceModal, setCancelInvoiceModal] = useState<boolean>(false)
	const [registerPaymentDate, setRegisterPaymentDate] = useState<Dayjs>()
	const [registerBankReference, setRegisterBankReference] = useState<string>('')
	const [showButtons, setShowButtons] = useState(true)
	const paymentComplementButton: PaymentComplementButton = useMemo(() => {
		const hideButton = [Constants.ERROR.ERROR, Constants.LINKS.MISSING_LINK, Constants.STATUS.IN_PROCESS]
		const validStatus = [PURCHASE.PAID, PURCHASE.PAYMENT_SCHEDULED]
		const status = invoice.status as Status
		return { show: !hideButton.includes(status.key), disable: !validStatus.includes(status.key) }
	}, [invoice.status])
	const [currentValidation, setCurrentValidation] = useState<Validations>(defaultValuesValidations)
	const [previousValidation, setPreviousValidation] = useState<Validations>(defaultValuesValidations)
	const [passAllValidations, setPassAllValidations] = useState<boolean>(false)
	const [openAuthInvoiceModal, setOpenAuthInvoiceModal] = useState<boolean>(false)
	const [openAuthMatchInvoiceModal, setOpenAuthMatchInvoiceModal] = useState<boolean>(false)
	const [openSummaryAuthInvoiceModal, setOpenSummaryAuthInvoiceModal] = useState<boolean>(false)
	const [overrideBy, setOverrideBy] = useState<Partial<User>>({ name: '', lastname: '', email: '' })
	const [overrideMessage, setOverrideMessage] = useState<string>('')
	const [overrideOn, setOverrideOn] = useState<string>('')
	const currentStatus = (invoice?.status as Status)?.key
	const isPurchaseOrder = invoice?.purchase_order
	const isConsumption = invoice?.consumption
	const isStandaloneDocument = invoice?.reference_type === Constants.DOCUMENT_TYPE.STANDALONE
	const isRelatedToAdvance = getIsRelatedToAdvance(invoice)

	const canCancel =
		isCustomer &&
		isStandaloneDocument &&
		isStatusInArray(currentStatus, [
			Constants.INVOICE.MISSING_LINK,
			Constants.STATUS.IN_APPROVAL,
			Constants.STATUS.ERROR
		])

	const canAuthorize =
		isCustomer &&
		isStandaloneDocument &&
		isStatusInArray(currentStatus, [Constants.STATUS.ERROR, Constants.STATUS.IN_APPROVAL])
	const canSendForApproval =
		isCustomer && isStandaloneDocument && isStatusInArray(currentStatus, [Constants.INVOICE.MISSING_LINK])

	const [existsPendingMessage, setExistsPendingMessage] = useState<boolean>(false)
	const [openRejectPendingMsgModal, setOpenRejectPendingMsgModal] = useState<boolean>(false)

	const [openAttachedDocumentsModal, setOpenAttachedDocumentsModal] = useState<boolean>(false)
	const [openAttachDocumentsModal, setOpenAttachDocumentsModal] = useState<boolean>(false)
	const disableAttachButton = isStatusInArray(currentStatus, [
		Constants.STATUS.CANCELED,
		Constants.ERROR.ERROR,
		PURCHASE.VOIDED
	])
	const [subLabelMessage, setSubLabelMessage] = useState<string>('')
	const { environment } = useEnvironment()
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }

	useEffect(() => {
		getInvoiceInfo()
	}, [])

	useEffect(() => {
		const statusText = (invoice?.status as Status)?.key ?? ''
		const validStatus = [PURCHASE.PAYMENT_SCHEDULED, Constants.LINKS.MISSING_LINK, Constants.ERROR.ERROR]
		const subLabelStatus = invoice?.cancellation_reason ? '' : t('invoices.NoCancelReason')
		if (validStatus.includes(statusText)) {
			setPaymentDate(t(PURCHASE.TEXT_PENDING))
			setBankReference(t(PURCHASE.TEXT_PENDING))
		} else {
			setPaymentDate(() => {
				try {
					return invoice?.payment_date
						? dateFormatter.format(new Date(invoice?.payment_date))
						: 'NA'
				} catch {
					return 'NA'
				}
			})
			setBankReference(invoice?.bank_reference ?? 'NA')
		}

		setSubLabelMessage(subLabelStatus)
	}, [invoice, t])

	const getInvoiceInfo = () => {
		showLoadingSpinner()
		const invoiceId = getUrlParameter('invoice')
		client
			.query({
				query: graphqlRequest.INVOICES_DETAIL,
				variables: {
					uuid: invoiceId
				},
				fetchPolicy: 'no-cache'
			})
			.then((result: ApolloQueryResult<{ Invoice: Invoice }>) => {
				const paramsToUrl = `?invoice=${invoiceId}`
				redirectByStatus(Constants.LISTS.IN, (result.data.Invoice.status as Status)?.key, paramsToUrl)
				if (result.data.Invoice) {
					setInvoice(result.data.Invoice)
					setComments(result.data.Invoice?.comments)
				} else {
					const errorCode = showErrorComponent(undefined)
					setErrorMessage({
						code: errorCode
					})
				}
			})
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const errorHandler = (error: Error) => {
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setErrorMessage({
				code: errorCode,
				message: error.message
			})
		}
		hideLoadingSpinner()
	}

	const addNewComment = (newCommentData: Comment) => {
		client
			.mutate({
				mutation: graphqlRequest.CREATE_INVOICE_COMMENT,
				variables: {
					invoice: invoice.uuid,
					text: newCommentData.text
				}
			})
			.then(() => {
				client
					.query({
						query: graphqlRequest.INVOICE_COMMENTS,
						variables: {
							uuid: invoice.uuid
						},
						fetchPolicy: 'no-cache'
					})
					.then((result) => {
						setComments(result.data.Invoice.comments)
					})
			})
			.catch(errorHandler)
			.finally(() => setExistsPendingMessage(false))
	}

	const handleCommentsSectionSizeChange = (commentsSectionSize: CommentSectionSize): void => {
		if (commentsSectionSize === CommentSectionSize.Extended) {
			setIsCommentsSectionExtended(true)
		} else {
			setIsCommentsSectionExtended(false)
		}
	}

	const modifiedBy = () => {
		const userType = isCustomer ? 'modified_by_customer' : 'modified_by_supplier'

		const userName = invoice
			? invoice[userType]
				? (invoice[userType] as Partial<User>)?.name +
					' ' +
					(invoice[userType] as Partial<User>)?.lastname
				: PURCHASE.TEXT_PENDING
			: ''
		const userEmail = invoice
			? invoice[userType]
				? (invoice[userType] as Partial<User>)?.email
				: ''
			: ''

		return { name: userName, email: userEmail }
	}

	const showInvoiceDocument = (): void => {
		if (invoice.uuid) {
			const fileName = `${invoice.uuid.toLowerCase()}.pdf`
			setFileViewer({
				open: true,
				titleText: `${t('invoiceText')} ${getSerieFolio(invoice.serie, invoice.folio)}`,
				fileSrc: `${S3_BUCKET}${fileName}`
			})
		}
	}

	const updateAmountValidationLabel = (validation: Validations) => {
		if (isStandaloneDocument) {
			if (validation && validation['amountValidation']) {
				delete validation['amountValidation']
			}
		} else {
			if (isRelatedToAdvance) {
				const customLabel = { customLabel: 'discountAmountValidation' }
				if (validation['amountValidation']) {
					validation['amountValidation'] = {
						...validation['amountValidation'],
						...customLabel
					}
				}
			} else if (invoice.advance) {
				const customLabel = { customLabel: 'advanceAmountValidation' }
				if (validation['amountValidation']) {
					validation['amountValidation'] = {
						...validation['amountValidation'],
						...customLabel
					}
				}
			} else {
				if (validation && validation['amountValidation']) {
					delete validation['amountValidation']
				}
			}
		}
		return validation
	}

	const executeValidation = () => {
		showLoadingSpinner()
		const query =
			invoice.advance && isStandaloneDocument
				? graphqlRequest.EXECUTE_MANUAL_AUTHORIZATION_STANDALONE_ADVANCE_INVOICE
				: graphqlRequest.EXECUTE_MANUAL_AUTHORIZATION_INVOICE
		client
			.query({
				query,
				variables: {
					uuid: invoice.uuid
				},
				fetchPolicy: 'no-cache'
			})
			.then(
				(
					result: ApolloQueryResult<{
						ExecuteValidations: InvoiceValidations
					}>
				) => {
					const passAllValidations = result.data.ExecuteValidations.passAllValidations
					const updatedCurrentValidation = updateAmountValidationLabel(
						result.data.ExecuteValidations.currentValidation
					)
					const updatedPreviousValidation = updateAmountValidationLabel(
						result.data.ExecuteValidations.previousValidation
					)
					setPassAllValidations(passAllValidations)
					setCurrentValidation(updatedCurrentValidation)
					setPreviousValidation(updatedPreviousValidation)
					if (passAllValidations && !isStandaloneDocument) {
						setOpenAuthMatchInvoiceModal(true)
					} else {
						setOpenAuthInvoiceModal(true)
					}
				}
			)
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const sendPaymentInvoicesData = () => {
		if (registerPaymentDate) {
			showLoadingSpinner()
			client
				.mutate({
					mutation: graphqlRequest.REGISTER_PAID_INVOICE,
					variables: {
						uuid: invoice.uuid,
						payment_date: Formatter.dateToSaveFormat(registerPaymentDate.toDate()),
						bank_reference: bankReference
					}
				})
				.then(() => {
					setRegisterPaymentDate(undefined)
					setRegisterBankReference('')
					setRegisterPaymentModal(false)
					getInvoiceInfo()
				})
				.catch(errorHandler)
		}
	}

	function onPaymentShapeChange(shape: PaymentComplementShape) {
		setShowButtons(shape === PaymentComplementShape.BUTTON)
	}

	const callManualAuthorization = (motive: string, additionalParams = {}) => {
		showLoadingSpinner()
		client
			.mutate({
				mutation: graphqlRequest.AUTHORIZE_INVOICE_MANUALLY,
				variables: {
					uuid: invoice.uuid,
					override_message: motive,
					...additionalParams
				}
			})
			.then(() => {
				setOpenAuthInvoiceModal(false)
				getInvoiceInfo()
			})
			.catch(errorHandler)
			.finally(() => {
				hideLoadingSpinner()
			})
	}

	const acceptAuthorization = () => {
		setOpenAuthMatchInvoiceModal(false)
		getInvoiceInfo()
	}

	const getManualAuthorizationSummary = () => {
		if (isStandaloneDocument) {
			setOpenAuthInvoiceModal(true)
			return
		}
		showLoadingSpinner()
		client
			.query({
				query: graphqlRequest.MANUAL_INVOICE_AUTHORIZATION,
				variables: {
					uuid: invoice.uuid
				},
				fetchPolicy: 'no-cache'
			})
			.then((result) => {
				setOverrideBy(result.data.ManualInvoiceAuthorization.overrideBy)
				setOverrideMessage(result.data.ManualInvoiceAuthorization.overrideMessage)
				const overrideOnValue = result.data.ManualInvoiceAuthorization.overrideOn
					? dateFormatter.format(new Date(result.data.ManualInvoiceAuthorization.overrideOn))
					: ''
				setOverrideOn(overrideOnValue)
				delete result.data.ManualInvoiceAuthorization.validations.__typename
				const validationUpdated = updateAmountValidationLabel(
					result.data.ManualInvoiceAuthorization.validations
				)
				setCurrentValidation(validationUpdated)
				setOpenSummaryAuthInvoiceModal(true)
				hideLoadingSpinner()
			})
			.catch(errorHandler)
	}

	const openAttachedDocumentModal = (): void => {
		setOpenAttachedDocumentsModal(true)
	}

	const openAttachDocumentModal = (): void => {
		setOpenAttachDocumentsModal(true)
	}

	return (
		<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start">
			<NetcurioGrid item alignItems="center" xs={12}>
				<AuthenticatedHeader>
					<div>
						<NetcurioButton
							variant="outlined"
							color="error"
							className={styles.btnHeader}
							onClick={() => {
								if (existsPendingMessage) {
									setOpenRejectPendingMsgModal(true)
								} else {
									history.push(URLS.INVOICE_LIST)
								}
							}}
							endIcon={<NetcurioIcons.ArrowBack className={classNames(styles.icon)} />}
						>
							<span> {t('comebackListText')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							endIcon={
								<NetcurioIcons.Download
									className={classNames(styles.icon, styles.iconDownloadXML)}
								/>
							}
							onClick={() => {
								downloadXMLFile(S3_BUCKET, invoice.uuid)
							}}
						>
							<span> {t('downloadText')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							onClick={showInvoiceDocument}
							endIcon={
								<NetcurioIcons.Visibility
									className={classNames(styles.icon, styles.iconPDF)}
								/>
							}
						>
							<span> {t('seeInvoice')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							onClick={openAttachDocumentModal}
							endIcon={
								<NetcurioIcons.AttachFile
									className={classNames(styles.icon, styles.iconPDF)}
								/>
							}
							disabled={disableAttachButton}
						>
							<span> {t('attachDocumentsTitle')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							className={styles.btnHeader}
							onClick={openAttachedDocumentModal}
							endIcon={
								<NetcurioIcons.Folder className={classNames(styles.icon, styles.iconPDF)} />
							}
							disabled={!invoice.folder?.files.length}
						>
							<span> {t('viewAttachments')} </span>
						</NetcurioButton>
					</div>
					<div></div>
				</AuthenticatedHeader>
			</NetcurioGrid>
			<NetcurioGrid container alignItems="flex-start">
				<NetcurioGrid
					item
					xs={paymentSelected.uuid ? 7 : 8}
					sm={paymentSelected.uuid ? 7 : 8}
					md={paymentSelected.uuid ? 7 : 9}
					lg={paymentSelected.uuid ? 7 : 9}
					xl={paymentSelected.uuid ? 7 : 10}
					justifyContent="flex-start"
					className={classNames(styles.mainSection)}
				>
					<NetcurioGrid container>
						<NetcurioGrid item xs={12}>
							<InvoiceInfoBar
								invoice={invoice}
								getManualAuthorizationSummary={getManualAuthorizationSummary}
							/>
						</NetcurioGrid>
						<NetcurioGrid container className={styles.invoiceInformationSection}>
							<NetcurioGrid container item xs={12} className={styles.statusSection}>
								<NetcurioGrid item className={styles.badgesSection}>
									<StatusBadges
										statusArray={getVisualStatusInvoiceDetail(
											invoice,
											(invoice?.status as Status)?.key,
											subLabelMessage
										)}
									/>
								</NetcurioGrid>
								<NetcurioGrid item className={styles.alertSection}>
									<InvoiceAlert
										invoice={invoice}
										isStandaloneDocument={isStandaloneDocument}
										executeValidation={executeValidation}
										amountValidation={currentValidation?.amountValidation}
										isAdmin={getCurrentUser()?.company?.is_admin ?? false}
										isRelatedToAdvance={isRelatedToAdvance}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid container item xs={12} className={styles.invoiceDetailsContainer}>
								<NetcurioGrid item xs={3}>
									<DetailField
										title={t('dateInvoicesHeader')}
										information={
											invoice?.date ? dateFormatter.format(new Date(invoice.date)) : ''
										}
									/>
									{isCustomer ? (
										<DetailField
											title={t('sender')}
											information={invoice?.sender?.name ?? ''}
											tooltip={invoice?.sender?.name}
										/>
									) : (
										<DetailField
											title={t('uploadedAt')}
											information={
												invoice?.created_at
													? dateFormatter.format(new Date(invoice.created_at))
													: ''
											}
										/>
									)}
									<DetailField
										title={t('rfcSender')}
										information={invoice?.sender?.rfc ?? ''}
										tooltip={isCustomer ? '' : invoice?.sender?.name}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<DetailField
										title={t('receiver')}
										information={invoice?.receiver?.name ?? ''}
										tooltip={invoice?.receiver?.name}
									/>
									<DetailField
										title={t('rfcReceiver')}
										information={invoice?.receiver?.rfc ?? ''}
									/>
									<DetailField
										title={t('UUID')}
										information={invoice?.uuid}
										tooltip={invoice?.uuid}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<DetailField
										title={t('proposedPaymentDate')}
										information={
											invoice?.proposed_payment_date
												? dateFormatter.format(
														new Date(invoice?.proposed_payment_date)
													)
												: t(PURCHASE.TEXT_PENDING)
										}
									/>
									{isCustomer ? (
										<DetailField
											title={t('editedBy')}
											information={t(modifiedBy().name)}
											tooltip={t(modifiedBy().email ?? '')}
										/>
									) : (
										<DetailField
											title={t('uploadedBy')}
											information={
												invoice?.uploaded_by
													? invoice?.uploaded_by?.name +
														' ' +
														invoice?.uploaded_by?.lastname
													: ''
											}
											tooltip={invoice?.uploaded_by?.email}
										/>
									)}
									{isCustomer ? (
										<DetailField
											title={t('dateLastModification')}
											information={
												invoice?.updated_at
													? dateFormatter.format(new Date(invoice.updated_at))
													: ''
											}
											tooltip={t('dateLastModification')}
										/>
									) : (
										<DetailField
											title={t('editedBy')}
											information={t(modifiedBy().name)}
											tooltip={t(modifiedBy().email ?? '')}
										/>
									)}
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<DetailField title={t('paymentDate')} information={paymentDate} />
									<DetailField title={t('bankReference')} information={bankReference} />
									{isCustomer ? (
										<DetailField
											title={t('uploadedAt')}
											information={
												invoice?.created_at
													? dateFormatter.format(new Date(invoice.created_at))
													: ''
											}
										/>
									) : (
										<DetailField
											title={t('dateLastModification')}
											information={
												invoice?.updated_at
													? dateFormatter.format(new Date(invoice.updated_at))
													: ''
											}
											tooltip={t('dateLastModification')}
										/>
									)}
								</NetcurioGrid>
							</NetcurioGrid>
						</NetcurioGrid>
						<NetcurioGrid
							container
							justifyContent={'flex-start'}
							alignItems={'center'}
							alignContent={'start'}
							className={styles.listContainer}
							marginTop="1rem"
						>
							<InvoiceItemList
								invoiceItems={invoice.items}
								referenceType={invoice?.reference_type}
								invoice={invoice}
							/>
						</NetcurioGrid>
					</NetcurioGrid>
				</NetcurioGrid>
				{!paymentSelected.uuid ? (
					<NetcurioGrid container item xs={4} sm={4} md={3} lg={3} xl={2} spacing={1}>
						{!isCommentsSectionExtended && (
							<NetcurioGrid item xs={12}>
								<TotalContainerBar
									title={'total'}
									fields={{
										currency: invoice?.currency,
										subtotalText: currencyFormatter.format(invoice?.subtotal ?? 0),
										discount: currencyFormatter.format(invoice?.discount ?? 0),
										ivaText: currencyFormatter.format(invoice?.iva ?? 0),
										ieps: currencyFormatter.format(invoice?.ieps ?? 0),
										isr: currencyFormatter.format(invoice?.isr ?? 0),
										ivaRet: currencyFormatter.format(invoice?.iva_ret ?? 0)
									}}
									total={{ totalDotText: currencyFormatter.format(invoice?.total ?? 0) }}
								/>
							</NetcurioGrid>
						)}

						<NetcurioGrid
							item
							xs={12}
							className={classNames(styles.commentSection, {
								[styles.grownSection]: isCommentsSectionExtended
							})}
						>
							<CommentsSection
								comments={comments}
								variant={CommentSectionVariant.WithBody}
								onSizeChange={(e: CommentSectionSize) => handleCommentsSectionSizeChange(e)}
								onAddComment={(e) => addNewComment(e)}
								onChange={(pendingMSg) => setExistsPendingMessage(!!pendingMSg)}
							/>
						</NetcurioGrid>
						{!isCommentsSectionExtended && (
							<NetcurioGrid item xs={12}>
								<AssociationSection
									associationType={
										isStandaloneDocument
											? 'noPO'
											: isPurchaseOrder
												? 'po'
												: isConsumption
													? 'consumption'
													: null
									}
									entityData={
										invoice?.purchase_order
											? (invoice.purchase_order as PurchaseOrder)
											: (invoice.consumption as Consumption)
									}
								/>
							</NetcurioGrid>
						)}
						{isStandaloneDocument ? (
							<NetcurioGrid item xs={12}>
								<div
									style={{
										display: 'flex',
										gap: 8,
										flexDirection: 'column',
										justifyContent: 'flex-end',
										alignItems: 'flex-end'
									}}
								>
									{canAuthorize && (
										<NetcurioButton
											variant="outlined"
											endIcon={<NetcurioIcons.Check />}
											size="medium"
											onClick={executeValidation}
										>
											<span> {t('invoices.reviewInvoice')} </span>
										</NetcurioButton>
									)}
									{canSendForApproval && (
										<SendInvoice
											invoice={invoice}
											errorHandler={errorHandler}
											onSendInvoiceSuccess={getInvoiceInfo}
											isStandaloneFlow={isStandaloneDocument}
										/>
									)}
									{canCancel && (
										<CancelInvoice
											invoice={invoice}
											errorHandler={errorHandler}
											getInvoiceInfo={getInvoiceInfo}
											isModalOpen={cancelInvoiceModal}
											onModalClose={() => setCancelInvoiceModal(false)}
										/>
									)}
								</div>
							</NetcurioGrid>
						) : (
							<CancelInvoice
								invoice={invoice}
								errorHandler={errorHandler}
								getInvoiceInfo={getInvoiceInfo}
								isModalOpen={cancelInvoiceModal}
								onModalClose={() => setCancelInvoiceModal(false)}
								showButton={false}
							/>
						)}

						{paymentComplementButton.show && !isCommentsSectionExtended && (
							<NetcurioGrid item xs={12}>
								<PaymentComplementInfo
									disabled={paymentComplementButton.disable}
									setPaymentSelected={setPaymentSelected}
									paymentComplementInfo={invoice.payment_complements ?? []}
									onShapeChange={onPaymentShapeChange}
								/>
							</NetcurioGrid>
						)}

						{!invoice.advance && (
							<NetcurioGrid item xs={12}>
								<RelatedAdvances invoice={invoice} />
							</NetcurioGrid>
						)}

						{!isCommentsSectionExtended && showButtons && (
							<NetcurioGrid item xs={12}>
								<InvoiceDetailButtons
									isCustomer={isCustomer}
									statusInvoice={invoice.status as Status}
									openRegisterPaymentModal={setRegisterPaymentModal}
									cancelButton={
										<CancelInvoice
											invoice={invoice}
											errorHandler={errorHandler}
											getInvoiceInfo={getInvoiceInfo}
											isModalOpen={cancelInvoiceModal}
											onModalClose={() => setCancelInvoiceModal(false)}
										/>
									}
								/>
							</NetcurioGrid>
						)}
					</NetcurioGrid>
				) : (
					<NetcurioGrid container item xs={5} sm={5} md={5} lg={5} xl={5} spacing={1}>
						<NetcurioGrid item xs={12}>
							<PaymentComplementInfoTable
								paymentSelected={paymentSelected}
								setPaymentSelected={setPaymentSelected}
								paymentComplementInfo={invoice.payment_complements ?? []}
							/>
						</NetcurioGrid>
					</NetcurioGrid>
				)}
			</NetcurioGrid>
			<FileViewerModal
				open={fileViewer.open}
				onClose={() => {
					setFileViewer(structuredClone(defaultFileViewer))
				}}
				titleText={fileViewer.titleText}
				fileSrc={fileViewer.fileSrc}
			/>
			<RegisterPaymentModal
				open={registerPaymentModal}
				onClose={() => {
					setRegisterPaymentDate(undefined)
					setRegisterBankReference('')
					setRegisterPaymentModal(false)
				}}
				onAccept={sendPaymentInvoicesData}
				registerBankReference={registerBankReference}
				registerPaymentDate={registerPaymentDate}
				setRegisterPaymentDate={setRegisterPaymentDate}
				setRegisterBankReference={setRegisterBankReference}
			/>
			<ManuallyAuthInvoiceSummaryModal
				onClose={() => setOpenSummaryAuthInvoiceModal(false)}
				open={openSummaryAuthInvoiceModal}
				override_by={overrideBy as User}
				override_message={overrideMessage}
				override_on={overrideOn}
				previousValidation={previousValidation}
				currentValidation={currentValidation}
				passAllValidations={false}
			/>
			<ManuallyAuthMatchModal
				open={openAuthMatchInvoiceModal}
				acceptAuthorization={() => acceptAuthorization()}
				previousValidation={previousValidation}
				currentValidation={currentValidation}
				passAllValidations={passAllValidations}
			/>
			{isStandaloneDocument ? (
				<StandaloneDocumentManuallyAuthModal
					open={openAuthInvoiceModal}
					onClose={() => {
						setOpenAuthInvoiceModal(false)
						getInvoiceInfo()
					}}
					invoice={invoice}
					previousValidation={previousValidation}
					currentValidation={currentValidation}
					onManualAuthorization={(
						motive,
						cost_center_key,
						expense_type_key,
						expense_subtype_key,
						bank_reference,
						payment_date
					) =>
						callManualAuthorization(motive, {
							cost_center_key,
							expense_type_key,
							expense_subtype_key,
							bank_reference,
							payment_date: payment_date
								? Formatter.dateToSaveFormat(new Date(payment_date))
								: undefined
						})
					}
				/>
			) : (
				<ManuallyAuthModal
					open={openAuthInvoiceModal}
					onClose={() => {
						setOpenAuthInvoiceModal(false)
						setPassAllValidations(false)
						getInvoiceInfo()
					}}
					triggerManualAuthorization={(motive) => callManualAuthorization(motive)}
					previousValidation={previousValidation}
					currentValidation={currentValidation}
					passAllValidations={passAllValidations}
					setCancelInvoiceModal={() => {
						setOpenAuthInvoiceModal(false)
						setPassAllValidations(false)
						setCancelInvoiceModal(true)
					}}
				/>
			)}
			<RejectPendingMessageInvoiceDetail
				open={openRejectPendingMsgModal}
				close={() => {
					setOpenRejectPendingMsgModal(false)
				}}
				onAccept={() => history.push(URLS.INVOICE_LIST)}
			/>
			<ErrorModal open={!!errorMessage?.code} errorCode={errorMessage?.code ?? ''} />
			<AttachedDocumentsModal
				open={openAttachedDocumentsModal}
				attachedDocuments={invoice.folder?.files}
				onClose={() => setOpenAttachedDocumentsModal(false)}
				folderUUID={invoice?.folder_uuid}
				getInvoiceInfo={getInvoiceInfo}
			/>
			<AttachDocumentsModal
				open={openAttachDocumentsModal}
				close={() => setOpenAttachDocumentsModal(false)}
				uuid={invoice?.uuid}
				getInvoiceInfo={getInvoiceInfo}
			/>
		</NetcurioGrid>
	)
}
