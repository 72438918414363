import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const CREATE_PURCHASE_REQUISITION: DocumentNode = gql`
	mutation createPurchaseRequisition($purchaseRequisition: CreatePurchaseRequisitionInput!) {
		createPurchaseRequisition(purchaseRequisition: $purchaseRequisition) {
			id
			message
		}
	}
`

export const SEND_PURCHASE_REQUISITION = gql`
	mutation sendPurchaseRequisition(
		$purchaseRequisition: CreateOrUpdatePurchaseRequisitionInput!
		$comment: String!
	) {
		sendPurchaseRequisition(purchaseRequisition: $purchaseRequisition, comment: $comment) {
			id
			message
		}
	}
`

export const UPDATE_PURCHASE_REQUISITION = gql`
	mutation updatePurchaseRequisition($purchaseRequisition: CreateOrUpdatePurchaseRequisitionInput!) {
		updatePurchaseRequisition(purchaseRequisition: $purchaseRequisition) {
			id
			message
		}
	}
`

export const SEND_PURCHASE_REQUISITION_TO_REVISION = gql`
	mutation sendPurchaseRequisitionToRevision($id: ID!, $comment: String!, $customerRfc: String) {
		sendPurchaseRequisitionToRevision(id: $id, comment: $comment, customerRfc: $customerRfc) {
			id
			message
		}
	}
`

export const APPROVE_PURCHASE_REQUISITION = gql`
	mutation approvePurchaseRequisition($id: ID!, $comment: String, $customerRfc: String) {
		approvePurchaseRequisition(id: $id, comment: $comment, customerRfc: $customerRfc) {
			id
			message
		}
	}
`
export const REJECT_PURCHASE_REQUISITION = gql`
	mutation rejectPurchaseRequisition($id: ID!, $comment: String!, $customerRfc: String) {
		rejectPurchaseRequisition(id: $id, comment: $comment, customerRfc: $customerRfc) {
			id
			message
		}
	}
`

export const CANCEL_PURCHASE_REQUISITION = gql`
	mutation cancelPurchaseRequisition($id: ID!, $customerRfc: String) {
		cancelPurchaseRequisition(id: $id, customerRfc: $customerRfc) {
			id
			message
		}
	}
`

export const CREATE_PURCHASE_REQUISITION_COMMENT: DocumentNode = gql`
	mutation createPurchaseRequisitionComment($id: ID!, $customerRfc: String, $comment: String!) {
		createPurchaseRequisitionComment(comment: $comment, id: $id, customerRfc: $customerRfc) {
			message
			code
		}
	}
`
