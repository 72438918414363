import { URLS } from '@netcurio/frontend-common'
import { NetcurioList } from '@netcurio/frontend-components'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { AddButton } from '../../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../../components/HeaderButtons/ExportButton'
import { useUserRoles } from '../../../hooks/useUserRoles'
import { PurchaseRequisitionInterface } from '../interfaces/purchase-requisition.interface'
import { usePurchaseRequisitionHeaders } from './hooks/usePurchaseRequisitionHeaders'
import { usePurchaseRequisitions } from './hooks/usePurchaseRequisitions'
import styles from './purchaseRequisitionList.module.scss'

export const PurchaseRequisitionList: FC = () => {
	const history = useHistory<string>()

	const headers = usePurchaseRequisitionHeaders()
	const { isRequester } = useUserRoles()

	const { purchaseRequisitions, hasMore, loadMore, loading, handleOnFiltersChange, handleOnChangeSort } =
		usePurchaseRequisitions()

	return (
		<main className={styles.containerMainViewList}>
			<div className={styles.containerViewList}>
				<AuthenticatedHeader>
					<div>
						{isRequester && (
							<AddButton
								onClick={() => history.push(URLS.PURCHASE_REQUISITION_DETAIL)}
								translationKey="purchaseRequisitions.actions.new"
							/>
						)}
					</div>
					<div>
						{/* <NetcurioTooltip title={t('pin')}>
							<div>
								<NetcurioButton
									variant="outlined"
									size="small"
									className={buttonStyles.button}
									onClick={() => {
										// handlePinPurchaseRequisitionsFeature(Array.from(uuidInvoices))
									}}
									endIcon={<NetcurioIcons.PushPin />}
									disabled={selectedPurchaseRequisitions.length === 0}
								>
									<span> {t('pin')} </span>
								</NetcurioButton>
							</div>
						</NetcurioTooltip>
						<NetcurioTooltip title={t('archive')}>
							<div>
								<NetcurioButton
									variant="outlined"
									size="small"
									className={buttonStyles.button}
									onClick={() => console.log('call saveArchivedPurchaseRequisitions()')}
									endIcon={<NetcurioIcons.Archive />}
									disabled={selectedPurchaseRequisitions.length === 0}
								>
									<span> {t('archive')} </span>
								</NetcurioButton>
							</div>
						</NetcurioTooltip>
						<NetcurioButton
							variant="outlined"
							className={buttonStyles.button}
							onClick={() => {
								// history.push(URLS.PURCHASE_REQUISITION_LIST_ARCHIVED)
							}}
							endIcon={<NetcurioIcons.TableRowsOutlined />}
							size="small"
						>
							<span> {t('seeArchived')} </span>
						</NetcurioButton>
                        */}
						<ExportButton onClick={() => console.log('Clicked Export List')} />
					</div>
				</AuthenticatedHeader>
				<div>
					<NetcurioList<PurchaseRequisitionInterface>
						dataSource={purchaseRequisitions}
						headers={headers}
						/* selection={{
							showSelectionColumn: true,
							selectedItems: selectedPurchaseRequisitions,
							onSelectChange: (item, checked) => {
								setSelectedPurchaseRequisitions((prev) =>
									checked
										? [...prev, item]
										: prev.filter(
												(purchaseRequisition) => purchaseRequisition.id !== item.id
											)
								)
							}
						}} */
						gridTemplateColumns="minmax(6rem, 8rem) minmax(6rem, 10rem) minmax(9rem, 10rem) minmax(12rem, 20rem) minmax(12rem, 16rem) minmax(9rem, 16rem) minmax(10rem, 17rem) minmax(6.5rem, 7rem) minmax(11rem, 13rem) 5rem"
						rowLink={{
							getRowHref: (item) =>
								`${URLS.PURCHASE_REQUISITION_DETAIL}?customer=${item.customerRfc}&id=${item.id}`
						}}
						infiniteScroll={{
							hasMore,
							loadMore,
							isLoadingMore: loading
						}}
						onFiltersChange={(filters) => handleOnFiltersChange(filters)}
						onOrderChange={(field: string, order: string) => handleOnChangeSort(field, order)}
					/>
				</div>
			</div>
		</main>
	)
}
