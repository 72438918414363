import { User } from '@netcurio/frontend-common'
import { useCallback, useEffect, useState } from 'react'
import { USERS } from '../views/administratorConsole/tabs/users/usersListTab/queries'
import { useClient } from './useClient'

export const useUsers = () => {
	const [users, setUsers] = useState<User[]>([])
	const client = useClient()

	const fetchUsers = useCallback(() => {
		client
			.query({
				query: USERS,
				variables: {
					limit: 1000,
					skip: 0,
					sort_field: 'name',
					sort_order: 'ASC',
					filter: []
				}
			})
			.then((result) => {
				if (result.data.Users) {
					setUsers(result.data.Users.list)
				}
			})
			.catch((error) => {
				console.error('Error fetching currencies:', error)
			})
	}, [client])

	useEffect(() => {
		fetchUsers()
	}, [fetchUsers])

	return { users }
}
