import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { CANCEL_PURCHASE_REQUISITION } from '../graphql-ops/mutations'

export const triggerCancelPurchaseRequisition = async (
	client: DefaultClient<NormalizedCacheObject>,
	id: number
): Promise<string> => {
	let result: string = ''
	try {
		const response = await client.mutate({
			mutation: CANCEL_PURCHASE_REQUISITION,
			variables: {
				id: id
			}
		})
		result = 'Success'
		console.log(response)
	} catch (e) {
		console.log(e)
		result = 'Error'
	}
	return result
}
