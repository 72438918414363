import { StepProgressBarProps } from '@netcurio/frontend-components'
import {
	Netcurio05ErrorAMain,
	Netcurio06WarningCLight,
	Netcurio07InfoAMain,
	Netcurio08SuccessAMain
} from '@netcurio/frontend-design-tokens'
import { PurchaseRequisitionStatusEnum } from '../enums/purchase-requisition-status.enum'
import { PurchaseRequisitionInterface } from '../interfaces/purchase-requisition.interface'

interface StatusDefinitionInterface {
	statusLabel: PurchaseRequisitionStatusEnum
	color: string
	dateField: string
	ancestors: Array<PurchaseRequisitionStatusEnum>
	predecessors: Array<PurchaseRequisitionStatusEnum>
}

const STATUS_DEFINITIONS: Record<string, StatusDefinitionInterface> = {
	NEW: {
		statusLabel: PurchaseRequisitionStatusEnum.NEW,
		color: Netcurio07InfoAMain,
		dateField: 'createdAt',
		ancestors: [],
		predecessors: []
	},
	PENDING: {
		statusLabel: PurchaseRequisitionStatusEnum.PENDING,
		color: Netcurio06WarningCLight,
		dateField: 'createdAt',
		ancestors: [],
		predecessors: [PurchaseRequisitionStatusEnum.APPROVED]
	},
	CANCELED: {
		statusLabel: PurchaseRequisitionStatusEnum.CANCELED,
		color: Netcurio05ErrorAMain,
		dateField: 'updatedAt',
		ancestors: [PurchaseRequisitionStatusEnum.PENDING],
		predecessors: []
	},
	UNDER_REVIEW: {
		statusLabel: PurchaseRequisitionStatusEnum.UNDER_REVIEW,
		color: Netcurio06WarningCLight,
		dateField: 'updatedAt',
		ancestors: [PurchaseRequisitionStatusEnum.PENDING],
		predecessors: []
	},
	APPROVED: {
		statusLabel: PurchaseRequisitionStatusEnum.APPROVED,
		color: Netcurio08SuccessAMain,
		dateField: 'updatedAt',
		ancestors: [PurchaseRequisitionStatusEnum.PENDING],
		predecessors: []
	},
	REJECTED: {
		statusLabel: PurchaseRequisitionStatusEnum.REJECTED,
		color: Netcurio05ErrorAMain,
		dateField: 'rejectedAt',
		ancestors: [PurchaseRequisitionStatusEnum.PENDING],
		predecessors: []
	}
}

export const getStatusSteps = (purchaseRequisition: PurchaseRequisitionInterface) => {
	const currentStatus = purchaseRequisition.status
	const stepsDefinition = STATUS_DEFINITIONS[currentStatus]
	const stepPointer = stepsDefinition?.ancestors.length + 1
	const stepsAncestors: Array<StepProgressBarProps> = []
	const stepsPredecessors: Array<StepProgressBarProps> = []

	stepsDefinition?.ancestors.forEach((ancestor, index) => {
		const ancestorStepDefinition = STATUS_DEFINITIONS[ancestor]
		if (ancestorStepDefinition) {
			const dateLabel = (purchaseRequisition as Record<string, any>)[ancestorStepDefinition?.dateField]
			stepsAncestors.push({
				stepPosition: index + 1,
				statusLabel: ancestorStepDefinition?.statusLabel,
				color: ancestorStepDefinition?.color,
				active: false,
				...(dateLabel ? { date: dateLabel } : {})
			})
		}
	})
	const currentStepDefinition: StepProgressBarProps = {
		stepPosition: stepPointer,
		statusLabel: stepsDefinition?.statusLabel,
		color: stepsDefinition?.color,
		active: true,
		...((purchaseRequisition as Record<string, any>)[stepsDefinition?.dateField]
			? { date: (purchaseRequisition as Record<string, any>)[stepsDefinition?.dateField] }
			: {})
	}

	stepsDefinition?.predecessors.forEach((predecessor, index) => {
		const predecessorStepDefinition = STATUS_DEFINITIONS[predecessor]
		if (predecessorStepDefinition) {
			stepsPredecessors.push({
				stepPosition: stepPointer + index + 1,
				statusLabel: predecessorStepDefinition.statusLabel,
				color: predecessorStepDefinition.color,
				active: false
			})
		}
	})
	return [...stepsAncestors, ...[currentStepDefinition], ...stepsPredecessors]
}
