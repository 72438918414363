import { Roles, tableFillSelectionFilter } from '@netcurio/frontend-common'
import Constants from '../../utilities/constants'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './requestForQuotationList.module.scss'

export function objectHeaderArray(userRoles: Roles[]): Array<tableFillHeader> {
	const requestColumn: tableFillHeader = new tableFillHeader(
		'requestRFQHeader',
		styles.headerRequest,
		'',
		'id',
		'text'
	)
	const customerReferenceColumn: tableFillHeader = new tableFillHeader(
		'externalIdentifier',
		styles.headerRequestReference,
		'',
		'customer_reference',
		'text'
	)
	const customerReferenceColumnSupplier: tableFillHeader = new tableFillHeader(
		'externalIdentifier',
		styles.headerRequestReferenceSupplier,
		'',
		'customer_reference',
		'text'
	)
	const statusColumn: tableFillHeader = new tableFillHeader(
		'statusRFQHeader',
		styles.headerStatus,
		'',
		'status',
		'status'
	)
	const branchColumn: tableFillHeader = new tableFillHeader(
		'branchOfficeRFQHeader',
		styles.headerOffice,
		'',
		'branch_office',
		'text'
	)
	const supplierColumn: tableFillHeader = new tableFillHeader(
		'supplierRFQHeader',
		styles.headerSupplier,
		'',
		'supplier',
		'text'
	)
	const createdDateColumn: tableFillHeader = new tableFillHeader(
		'createdDateRFQHeader',
		styles.headerDate,
		'',
		'created_at',
		'date'
	)
	const createdByColumn: tableFillHeader = new tableFillHeader(
		'createdByRFQHeader',
		styles.headerCreated,
		'',
		'created_by',
		'text'
	)
	const requestColumnSupplier: tableFillHeader = new tableFillHeader(
		'requestRFQHeader',
		styles.headerRequestSupplier,
		'',
		'id',
		'text'
	)
	const statusColumnSupplier: tableFillHeader = new tableFillHeader(
		'statusRFQHeader',
		styles.headerStatusSupplier,
		'',
		'status',
		'status'
	)
	const branchColumnSupplier: tableFillHeader = new tableFillHeader(
		'branchOfficeRFQHeader',
		styles.headerOfficeSupplier,
		'',
		'branch_office',
		'text'
	)
	const customerColumn: tableFillHeader = new tableFillHeader(
		'customerRFQHeader',
		styles.headerCustomer,
		'',
		'customer',
		'text'
	)
	const createdDateColumnSupplier: tableFillHeader = new tableFillHeader(
		'createdDateRFQHeader',
		styles.headerDateSupplier,
		'',
		'created_at',
		'date'
	)

	if (userRoles.includes(Roles.CUSTOMER)) {
		return [
			requestColumn,
			customerReferenceColumn,
			statusColumn,
			branchColumn,
			supplierColumn,
			createdDateColumn,
			createdByColumn
		]
	} else {
		return [
			requestColumnSupplier,
			customerReferenceColumnSupplier,
			statusColumnSupplier,
			branchColumnSupplier,
			customerColumn,
			createdDateColumnSupplier
		]
	}
}

export function objectStatusFilterArray(): Array<tableFillSelectionFilter> {
	const newStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.STATUS.NEW,
		'new-status-button'
	)
	const quotedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.QUOTATION.QUOTED,
		'quoted-status-button'
	)
	const rejectedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.STATUS.REJECTED,
		'rejected-rfq-status-button'
	)
	const closedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.QUOTATION.CLOSED,
		'closed-status-button'
	)

	return [newStatusOption, quotedStatusOption, rejectedStatusOption, closedStatusOption]
}
