import { currencyFormatter, getCurrentUser, URLS, User } from '@netcurio/frontend-common'
import {
	ApprovalFlowSection,
	Autocomplete,
	CustomInputLabel,
	CustomMenuItem,
	NetcurioButton,
	NetcurioFormControl,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioMenuItem,
	NetcurioSelectChangeEvent,
	OutlinedSelect,
	OutlinedTextField,
	Severity,
	StepProgressBar,
	StepProgressBarProps,
	TotalContainerBar,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { TableContentItem } from '@netcurio/frontend-components/src'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { DocumentNode } from 'graphql'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import buttonStyles from '../../../common/styles/button.module.scss'
import commonDetail from '../../../common/styles/detail.module.scss'
import { AuthenticatedHeader } from '../../../components/AuthenticatedHeader/AuthenticatedHeader'
import { ConditionalRender } from '../../../components/ConditionalRender/ConditionalRender'
import { useAreas } from '../../../hooks/useAreas'
import { useBranches } from '../../../hooks/useBranches'
import { useClient } from '../../../hooks/useClient'
import { useCurrencies } from '../../../hooks/useCurrencies'
import { useMeasurementUnits } from '../../../hooks/useMeasurementUnits'
import { useUserRoles } from '../../../hooks/useUserRoles'
import { useUsers } from '../../../hooks/useUsers'
import { Area } from '../../../types'
import { getUrlParameter } from '../../../utilities/getUrlParameter'
import { useDebounce } from '../../../utilities/useDebounce'
import { USERS } from '../../administratorConsole/tabs/users/usersListTab/queries'
import { ErrorModal } from '../../invoices/InvoiceEdit/Modals'
import { getStatusSteps } from '../business-rules/get-status-steps'
import { triggerCancelPurchaseRequisition } from '../business-rules/trigger-cancel-purchase-requisition'
import { triggerGetPurchaseRequisitionDetail } from '../business-rules/trigger-get-purchase-requisition-detail'
import { PurchaseRequisitionStatusEnum } from '../enums/purchase-requisition-status.enum'
import { PurchaseRequisitionTypes } from '../enums/purchase-requisition-types.enum'
import {
	CREATE_PURCHASE_REQUISITION,
	SEND_PURCHASE_REQUISITION,
	UPDATE_PURCHASE_REQUISITION
} from '../graphql-ops/mutations'
import { usePurchaseRequisitionComments } from '../hooks/usePurchaseRequisitionComments'
import { PurchaseRequisitionApprovalInterface } from '../interfaces/purchase-requisition-approval.interface'
import { PurchaseRequisitionItemInterface } from '../interfaces/purchase-requisition-item.interface'
import { PurchaseRequisitionInterface } from '../interfaces/purchase-requisition.interface'
import { CancelPurchaseRequestModal } from '../modals/cancelConfirmationModal'
import {
	defaultValuesItemPr,
	defaultValuesPurchaseRequisition
} from './default-values/defaultValuesPurchaseRequisition'
import { Approve } from './PurchaseRequisitionActions/Approve'
import { Reject } from './PurchaseRequisitionActions/Reject/Reject'
import { ReturnToReview } from './PurchaseRequisitionActions/ReturnToReview/ReturnToReview'
import { PurchaseRequisitionComments } from './PurchaseRequisitionComments/PurchaseRequisitionComments'
import styles from './purchaseRequisitionDetail.module.scss'
import { PurchaseRequisitionPosition } from './PurchaseRequisitionPosition/PurchaseRequisitionPosition'
import { RequisitionAlert } from './requisitionAlert/requisitionAlert'

export interface FieldValidations {
	supplier: boolean
	product: boolean
	quantity: boolean
	unit_measure: boolean
	unit_cost: boolean
	required_date: boolean
}

export const PurchaseRequisitionDetail: FC = () => {
	const CHARACTERS_LIMIT = 120
	const client = useClient()
	const user = getCurrentUser()
	const totalContainerRef = useRef<HTMLDivElement>(null)
	const history: RouterChildContext['router']['history'] = useHistory()
	const purchaseID = getUrlParameter('id') ? parseInt(getUrlParameter('id')) : undefined
	const recentlyCreated = getUrlParameter('recentlyCreated') ?? false
	const queryParams = new URLSearchParams(history.location.search)

	const { t } = useTranslation()
	const { currencies } = useCurrencies()
	const { users } = useUsers()
	const { isRequester } = useUserRoles()
	const { measurementUnits } = useMeasurementUnits()
	const { areas } = useAreas(user?.company?.rfc ?? '')
	const { branches } = useBranches(user?.company?.rfc)
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const [suggestedUsers, setSuggestedUsers] = useState<User[]>(users)
	const [userPreSavedObject, setUserPreSavedObject] = useState<User | undefined>(user ?? undefined)
	const [userInputValue, setUserInputValue] = useState<string>('')
	const [userLoading, setUserLoading] = useState<boolean>(false)
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
	const [headerFormDisabled, setHeaderFormDisabled] = useState<boolean>(false)
	const [positionsAndButtonsDisabled, setPositionsAndButtonsDisabled] = useState<boolean>(false)
	const [cancelModalVisibility, setCancelModalVisibility] = useState<boolean>(false)
	const [isCommentsSectionExtended, setIsCommentsSectionExtended] = useState<boolean>(false)
	const [totalErrorsInPositions, setTotalErrorsInPositions] = useState<Array<FieldValidations>>([])
	const [approvalFlow, setApprovalFlow] = useState<TableContentItem | undefined>(undefined)
	const [alert, setAlert] = useState<{
		message: string
		severity: Severity
	} | null>(null)

	const defaultValue = {
		...defaultValuesPurchaseRequisition,
		requester: user ?? {},
		requesterEmail: user?.email ?? '',
		createdBy: user ?? {},
		customerRfc: user?.company?.rfc ?? '',
		currency: 'MXN',
		deliveryAddressCountry: user?.company?.country_description,
		deliveryAddressCountryCode: user?.company?.country_code,
		deliveryAddressLine1: user?.company?.address_line_1,
		deliveryAddressLine2: user?.company?.address_line_2,
		deliveryAddressPostalCode: user?.company?.postal_code,
		deliveryAddressState: user?.company?.state_description,
		deliveryAddressStateCode: user?.company?.state_code,
		items: [defaultValuesItemPr]
	}

	const [purchaseRequisition, setPurchaseRequisition] = useState<PurchaseRequisitionInterface>(defaultValue)
	const [initialValue, setInitialValue] = useState<PurchaseRequisitionInterface>(defaultValue)

	const { comments, addComment } = usePurchaseRequisitionComments({
		purchaseRequisitionId: purchaseID,
		customerRfc: purchaseRequisition?.customerRfc ?? '',
		initialComments: purchaseRequisition.comments ?? []
	})
	const initialSteps = getStatusSteps(purchaseRequisition)
	const [stepArray, setStepArray] = useState<StepProgressBarProps[]>(initialSteps)
	const [userSearchString, setUserSearchString] = useState<string>(purchaseRequisition.requesterEmail)
	const debouncedUserInput = useDebounce<string>(userSearchString, 500)

	/* EFFECTS */
	useEffect(() => {
		if (recentlyCreated) {
			setAlert({
				severity: Severity.Info,
				message: t('purchaseRequisitions.detail.informationSaved')
			})
			if (queryParams.has('recentlyCreated')) {
				queryParams.delete('recentlyCreated')
				history.replace({ search: queryParams.toString() })
			}
		}
	}, [purchaseID])
	useEffect(() => {
		if (purchaseID) {
			showLoadingSpinner()
			getPurchaseDetail(purchaseID).then()
		}
	}, [purchaseID])
	useEffect(() => {
		if (
			[
				PurchaseRequisitionStatusEnum.CANCELED,
				PurchaseRequisitionStatusEnum.APPROVED,
				PurchaseRequisitionStatusEnum.REJECTED
			].includes(purchaseRequisition.status as PurchaseRequisitionStatusEnum)
		) {
			setPositionsAndButtonsDisabled(true)
			setHeaderFormDisabled(true)
		} else if (
			[
				PurchaseRequisitionStatusEnum.PENDING
			].includes(purchaseRequisition.status as PurchaseRequisitionStatusEnum)
		) {
			if (purchaseRequisition?.approvals?.every(
					(approval) => approval?.approvedAt === null
				)) {
				setHeaderFormDisabled(true)
			} else {
				setPositionsAndButtonsDisabled(true)
			setHeaderFormDisabled(true)
			}
		}
	}, [purchaseRequisition])
	useEffect(() => {
		if (debouncedUserInput.length >= 3) {
			setUserLoading(true)
			suggestUsers(debouncedUserInput)
		}
	}, [debouncedUserInput])

	/* HEADER BUTTONS LOGIC */
	const handleClose = () => {
		history.push(URLS.PURCHASE_REQUISITION_LIST)
	}
	/*const handleOpenAttachments = () => {
		console.log('Clicked OpenAttachments')
	}*/
	const handleSubmit = async () => {
		try {
			showLoadingSpinner()
			if (purchaseRequisition.status === PurchaseRequisitionStatusEnum.UNDER_REVIEW && isRequester) {
				const purchaseRequisitionCopy = { ...purchaseRequisition }
				delete purchaseRequisitionCopy.comments
				const initialValueCopy = { ...initialValue }
				delete initialValueCopy.comments

				const hasChanges =
					JSON.stringify(purchaseRequisitionCopy) !== JSON.stringify(initialValueCopy)
				if (hasChanges && comments.length === purchaseRequisition.comments?.length) {
					setAlert({
						severity: Severity.Warning,
						message: t('purchaseRequisitions.detail.changeDetected')
					})
					return
				}
				setAlert(null)
				return
			}
			if (purchaseRequisition.status === PurchaseRequisitionStatusEnum.NEW) {
				if (checkMissingFields()) {
					const purchaseRequisitionDTO = {
						...(purchaseRequisition.id ? { id: purchaseID } : {}),
						customerRfc: purchaseRequisition.customerRfc,
						currency: purchaseRequisition.currency,
						requesterEmail: purchaseRequisition.requesterEmail,
						type: purchaseRequisition.type,
						description: purchaseRequisition.description,
						branchOfficeId: purchaseRequisition.branchOfficeId,
						branchOffice: purchaseRequisition.branchOffice,
						deliveryAddressLine1: purchaseRequisition.deliveryAddressLine1,
						deliveryAddressLine2: purchaseRequisition.deliveryAddressLine2,
						deliveryAddressPostalCode: purchaseRequisition.deliveryAddressPostalCode,
						deliveryAddressStateCode: purchaseRequisition.deliveryAddressStateCode,
						deliveryAddressCountry: purchaseRequisition.deliveryAddressCountry,
						deliveryAddressCountryCode: purchaseRequisition.deliveryAddressCountryCode,
						areaId: purchaseRequisition.areaId,
						items: purchaseRequisition.items
					}
					const comment = comments[0] ? comments[0].text : ''
					sendPurchaseRequisition(purchaseRequisitionDTO, comment)
					return
				}
			}
			checkMissingFields()
		} catch {
			setShowErrorModal(true)
		} finally {
			hideLoadingSpinner()
		}
	}
	const handleSave = async () => {
		try {
			showLoadingSpinner()

			const isUpdating = purchaseID
			const mutationResultNode = isUpdating ? 'updatePurchaseRequisition' : 'createPurchaseRequisition'
			const mutationPurchaseRequisition = isUpdating
				? UPDATE_PURCHASE_REQUISITION
				: CREATE_PURCHASE_REQUISITION

			const purchaseRequisitionDTO = {
				...(purchaseRequisition.id ? { id: purchaseID } : {}),
				customerRfc: purchaseRequisition.customerRfc,
				currency: purchaseRequisition.currency,
				requesterEmail: purchaseRequisition.requesterEmail,
				type: purchaseRequisition.type,
				description: purchaseRequisition.description,
				branchOfficeId: purchaseRequisition.branchOfficeId,
				branchOffice: purchaseRequisition.branchOffice,
				deliveryAddressLine1: purchaseRequisition.deliveryAddressLine1,
				deliveryAddressLine2: purchaseRequisition.deliveryAddressLine2,
				deliveryAddressPostalCode: purchaseRequisition.deliveryAddressPostalCode,
				deliveryAddressStateCode: purchaseRequisition.deliveryAddressStateCode,
				deliveryAddressCountry: purchaseRequisition.deliveryAddressCountry,
				deliveryAddressCountryCode: purchaseRequisition.deliveryAddressCountryCode,
				areaId: purchaseRequisition.areaId,
				items: purchaseRequisition.items
			}

			await createOrSavePurchaseRequisition(
				mutationPurchaseRequisition,
				mutationResultNode,
				purchaseRequisitionDTO
			)
		} catch {
			setShowErrorModal(true)
		} finally {
			hideLoadingSpinner()
		}
	}

	/* OTHERS */
	const checkMissingFields = (): boolean => {
		const items = purchaseRequisition.items
		let isValidItem = true
		const errorsByItem: FieldValidations[] = []
		items.map((item: PurchaseRequisitionItemInterface) => {
			const itemErrors: FieldValidations = {
				supplier: !item.supplierRfc,
				product: !item.description,
				quantity: !item.unit || item.unit === '',
				unit_measure: !item.unit,
				unit_cost: !item.unitPrice,
				required_date: !item.requiredDeliveryDate
			}
			if (Object.values(itemErrors).some((error) => error)) {
				isValidItem = false
			}
			errorsByItem.push(itemErrors)
		})
		setTotalErrorsInPositions(errorsByItem)
		return isValidItem
	}
	const createOrSavePurchaseRequisition = useCallback(
		async (mutation: DocumentNode, resultNode: string, dto: object) => {
			return client
				.mutate({ mutation: mutation, variables: { purchaseRequisition: dto } })
				.then(async (result) => {
					if (result.data[resultNode].id) {
						if (resultNode === 'updatePurchaseRequisition') {
							setAlert({
								severity: Severity.Info,
								message: t('purchaseRequisitions.detail.informationSaved')
							})
						} else {
							const params = new URLSearchParams({
								customer: purchaseRequisition.customerRfc,
								id: result.data[resultNode].id,
								recentlyCreated: 'true'
							})
							history.push(`${URLS.PURCHASE_REQUISITION_DETAIL}?${params.toString()}`)
						}
					}
				})
		},
		[purchaseRequisition, branches, history]
	)
	const sendPurchaseRequisition = useCallback(
		async (dto: object, comment: string | undefined) => {
			return client
				.mutate({
					mutation: SEND_PURCHASE_REQUISITION,
					variables: { purchaseRequisition: dto, comment: comment }
				})
				.then(async (result) => {
					if (result.data.sendPurchaseRequisition.id) {
						const params = new URLSearchParams({
							customer: purchaseRequisition.customerRfc,
							id: result.data.sendPurchaseRequisition.id,
							recentlyCreated: 'true'
						})
						history.push(`${URLS.PURCHASE_REQUISITION_DETAIL}?${params.toString()}`)
						window.location.reload()
					}
				})
		},
		[purchaseRequisition, branches, history]
	)
	const createApprovalsObject = (approvalsArr: PurchaseRequisitionApprovalInterface[]) => {
		const provisionalObject: TableContentItem = {
			titles: { title1: t('approvals.table.name'), title2: t('approvals.table.status') },
			rows: [{ name: '', status: '' }]
		}
		const newRows = approvalsArr.map((approver) => {
			let approvalStatus
			if (approver.approvedAt) approvalStatus = t('APPROVED')
			else if (approver.rejectedAt) approvalStatus = t('REJECTED')
			else approvalStatus = t('pending')
			return {
				name: `${approver.approver.name} ${approver.approver.lastname}`,
				status: approvalStatus
			}
		})
		setApprovalFlow({ ...provisionalObject, rows: newRows })
	}
	const getPurchaseDetail = async (idPurchase: number | undefined): Promise<any> => {
		const detailResult: PurchaseRequisitionInterface | 'Error' =
			await triggerGetPurchaseRequisitionDetail(client, idPurchase ?? 0, user?.company?.rfc ?? '')
		if (detailResult !== 'Error') {
			const value = {
				...purchaseRequisition,
				id: detailResult.id,
				requester: detailResult.requester,
				type: detailResult.type ?? purchaseRequisition.type,
				status: detailResult.status ?? purchaseRequisition.status,
				description: detailResult.description ?? purchaseRequisition.description,
				branchOfficeId: detailResult.branchOfficeId ?? purchaseRequisition.branchOfficeId,
				branchOffice: detailResult.branchOffice ?? purchaseRequisition.branchOffice,
				deliveryAddressLine1:
					detailResult.deliveryAddressLine1 ?? purchaseRequisition.deliveryAddressLine1,
				deliveryAddressLine2:
					detailResult.deliveryAddressLine2 ?? purchaseRequisition.deliveryAddressLine2,
				deliveryAddressPostalCode:
					detailResult.deliveryAddressPostalCode ?? purchaseRequisition.deliveryAddressPostalCode,
				deliveryAddressStateCode:
					detailResult.deliveryAddressStateCode ?? purchaseRequisition.deliveryAddressStateCode,
				deliveryAddressState:
					detailResult.deliveryAddressState ?? purchaseRequisition.deliveryAddressState,
				deliveryAddressCountryCode:
					detailResult.deliveryAddressCountryCode ?? purchaseRequisition.deliveryAddressCountryCode,
				deliveryAddressCountry:
					detailResult.deliveryAddressCountry ?? purchaseRequisition.deliveryAddressCountry,
				currency: detailResult.currency ?? purchaseRequisition.currency,
				areaId: detailResult?.area?.id ?? purchaseRequisition.areaId,
				items: detailResult.items ?? purchaseRequisition.items,
				approvals: detailResult.approvals ?? purchaseRequisition.approvals,
				comments: detailResult.comments ?? purchaseRequisition.comments,
				createdBy: detailResult.createdBy ?? purchaseRequisition.createdBy,
				customerRfc: detailResult.customerRfc ?? purchaseRequisition.customerRfc,
				total: detailResult.total ?? purchaseRequisition.total,
				createdAt: detailResult.createdAt ?? purchaseRequisition.createdAt,
				requesterEmail: detailResult.requester.email ?? purchaseRequisition.requesterEmail
			}
			setPurchaseRequisition(value)
			setInitialValue(value)
			const currentSteps = getStatusSteps(detailResult)
			setStepArray(currentSteps)
			if (detailResult.approvals && detailResult.approvals?.length > 0)
				createApprovalsObject(detailResult.approvals)
		} else {
			setShowErrorModal(true)
		}
		setCancelModalVisibility(false)
		hideLoadingSpinner()
	}
	const handleFieldChange = <T extends keyof PurchaseRequisitionInterface>(
		event: React.ChangeEvent<HTMLInputElement> | NetcurioSelectChangeEvent,
		field: T
	) => {
		setPurchaseRequisition((prevState) => ({
			...prevState,
			[field]: event.target.value
		}))
	}
	const handleCancelPRConfirmation = async () => {
		showLoadingSpinner()
		if (purchaseID) {
			const cancelResult = await triggerCancelPurchaseRequisition(client, purchaseID)
			if (cancelResult === 'Success') {
				handleClose()
			} else {
				setShowErrorModal(true)
				setCancelModalVisibility(false)
			}
		} else {
			handleClose()
		}
		hideLoadingSpinner()
	}
	const getUserSuggestion = (searchText: string) => {
		setUserSearchString(searchText)
		setUserInputValue(searchText)
	}
	const suggestUsers = (debouncedSupplierInput: string) => {
		client
			.query({
				query: USERS,
				variables: {
					limit: 100,
					skip: 0,
					sort_field: 'name',
					sort_order: 'ASC',
					filter: [
						{ type: 'wildcard', column_name: 'name_lastname', value: debouncedSupplierInput }
					]
				}
			})
			.then((result) => {
				if (result.data.Users) {
					setSuggestedUsers(result.data.Users.list)
					if (purchaseRequisition.requesterEmail && purchaseRequisition && !userPreSavedObject) {
						setUserPreSavedObject(result.data.Users[0])
					}
					setUserLoading(false)
				}
			})
			.catch((error) => {
				console.error('Error fetching currencies:', error)
			})
	}

	/* POSITIONS LOGIC */
	const addPosition = () => {
		const newItem: PurchaseRequisitionItemInterface = defaultValuesItemPr
		let items = purchaseRequisition.items
		items.push(newItem)
		items = items.map((item, index) => {
			return { ...item, position: index + 1 }
		})
		setPurchaseRequisition({
			...purchaseRequisition,
			items: items,
			total: getNetTotal(items)
		})
	}
	const deletePosition = (positionIndex: number) => {
		const items = purchaseRequisition.items
		if (items.length <= 1) {
			setPurchaseRequisition({
				...purchaseRequisition,
				items: [defaultValuesItemPr],
				total: 0
			})
		} else {
			items.splice(positionIndex, 1)
			setPurchaseRequisition({
				...purchaseRequisition,
				items: items,
				total: getNetTotal(items)
			})
		}
	}
	const selectProductPosition = (evt: any, index: number) => {
		setPurchaseRequisition((prev) => ({
			...prev,
			items: prev.items.map((item, i) =>
				i === index
					? {
							...item,
							code: evt.id,
							description: evt.description,
							unit: evt.unit,
							unitPrice: evt.unit_price,
							quantity: 0
						}
					: item
			)
		}))
	}
	const handlePositionFieldChange = (evt: any, index: number, field: string) => {
		setPurchaseRequisition((prev) => {
			const updatedItems = prev.items.map((item, i) => {
				if (i !== index) return item

				return {
					...item,
					supplierRfc: field === 'supplier' ? (evt.rfc ?? '') : item.supplierRfc,
					quantity: field === 'quantity' ? parseFloat(evt) : item.quantity,
					unit: field === 'measurementUnit' ? evt : item.unit,
					requiredDeliveryDate:
						field === 'requiredDate' ? dayjs(evt).toDate() : item.requiredDeliveryDate,
					unitPrice: field === 'unitPrice' ? parseFloat(evt) : item.unitPrice,
					netAmount:
						field === 'quantity' || field === 'unitPrice'
							? (parseFloat(evt) || 0) *
								(field === 'quantity' ? (item.unitPrice ?? 0) : (item.quantity ?? 0))
							: item.netAmount,
					customerRfc: user?.company?.rfc ?? '',
					position: index + 1
				}
			})

			return {
				...prev,
				items: updatedItems,
				total: getNetTotal(updatedItems)
			}
		})
	}
	const getNetTotal = (item: PurchaseRequisitionItemInterface[]) => {
		return item.reduce((total, num) => {
			if (typeof num.netAmount === 'number') {
				return total + num.netAmount
			} else {
				return total
			}
		}, 0)
	}

	return (
		<NetcurioGrid
			container
			minWidth="100%"
			minHeight="100vh"
			display="grid"
			gridTemplateRows="5.5rem 1fr"
		>
			<CancelPurchaseRequestModal
				onClose={() => setCancelModalVisibility(false)}
				onAccept={handleCancelPRConfirmation}
				open={cancelModalVisibility}
			/>
			<AuthenticatedHeader>
				<div>
					<ConditionalRender condition={isRequester}>
						<ConditionalRender
							condition={
								purchaseRequisition.status === PurchaseRequisitionStatusEnum.NEW ||
								(purchaseRequisition.status === PurchaseRequisitionStatusEnum.PENDING &&
									Boolean(
										purchaseRequisition?.approvals?.every(
											(approval) => approval?.approvedAt === null
										)
									))
							}
						>
							<NetcurioButton
								color="error"
								variant={'outlined'}
								className={buttonStyles.button}
								onClick={() => setCancelModalVisibility(true)}
								size="small"
								endIcon={<NetcurioIcons.Close />}
							>
								<span> {t('purchaseRequisitions.actions.cancel')} </span>
							</NetcurioButton>
						</ConditionalRender>

						<ConditionalRender
							condition={[
								PurchaseRequisitionStatusEnum.NEW,
								PurchaseRequisitionStatusEnum.UNDER_REVIEW
							].includes(purchaseRequisition.status as PurchaseRequisitionStatusEnum)}
						>
							<NetcurioButton
								color="primary"
								variant={'contained'}
								onClick={handleSubmit}
								size="small"
								endIcon={<NetcurioIcons.Check />}
							>
								<span> {t('purchaseRequisitions.actions.send')} </span>
							</NetcurioButton>
						</ConditionalRender>

						<ConditionalRender
							condition={purchaseRequisition.status === PurchaseRequisitionStatusEnum.NEW}
						>
							<NetcurioButton
								color="primary"
								variant={'outlined'}
								onClick={handleSave}
								size="small"
								endIcon={<NetcurioIcons.SaveOutlined />}
							>
								<span> {t('purchaseRequisitions.actions.save')} </span>
							</NetcurioButton>
						</ConditionalRender>
					</ConditionalRender>
					<Reject
						purchaseRequisition={purchaseRequisition}
						onReject={() => {
							getPurchaseDetail(purchaseID).then()
						}}
					/>
					<ReturnToReview
						purchaseRequisition={purchaseRequisition}
						onReturnToReview={() => {
							getPurchaseDetail(purchaseID).then()
						}}
					/>
					<Approve
						purchaseRequisition={purchaseRequisition}
						onApprove={() => {
							getPurchaseDetail(purchaseID).then()
							setAlert({
								severity: Severity.Info,
								message: t('purchaseRequisitions.detail.successApproved')
							})
						}}
					/>
				</div>
				<div>
					{/*<NetcurioButton
						variant="outlined"
						className={commonDetail.btnHeader}
						onClick={handleOpenAttachments}
						endIcon={
							<NetcurioIcons.AttachFile
								className={classNames(commonDetail.icon, commonDetail.iconPDF)}
							/>
						}
					>
						<span> {t('purchaseRequisitions.detail.attachments')} </span>
					</NetcurioButton>*/}
				</div>
			</AuthenticatedHeader>
			<NetcurioGrid container alignItems="flex-start" height="100%">
				<NetcurioGrid item xs={9} height="100%" width="100%">
					<NetcurioGrid item container xs={12} height="auto">
						<NetcurioGrid item xs={12} className={commonDetail.cardContainer}>
							<NetcurioGrid className={commonDetail.cardTitle}>
								{t('purchaseRequisitions.detail.title')}
							</NetcurioGrid>
						</NetcurioGrid>
						<NetcurioGrid item container xs={12} className={commonDetail.informationSection}>
							<NetcurioGrid
								item
								container
								xs={12}
								spacing={2}
								marginTop={'2rem'}
								marginBottom={'1.5rem'}
							>
								<NetcurioGrid item container xs={6}>
									<StepProgressBar stepProgressArray={stepArray} />
								</NetcurioGrid>
								<NetcurioGrid item container xs={6}>
									<RequisitionAlert alert={alert} />
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid className={commonDetail.subtitleModule}>
								{t('purchaseRequisitions.detail.dataOf')}
							</NetcurioGrid>
							<div className={commonDetail.dividingLine}></div>
							<NetcurioGrid item container xs={12}>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="pr_type"
											label={t('purchaseRequisitions.detail.type')}
											size="small"
											disabled={headerFormDisabled}
										/>
										<OutlinedSelect
											label={t('purchaseRequisitions.detail.type')}
											labelId={'pr_type'}
											value={purchaseRequisition.type}
											onChange={(e) => handleFieldChange(e, 'type')}
											disabled={headerFormDisabled}
										>
											<NetcurioMenuItem value={PurchaseRequisitionTypes.MATERIALS}>
												{t(
													`purchaseRequisitions.types.${PurchaseRequisitionTypes.MATERIALS}`
												)}
											</NetcurioMenuItem>
											<NetcurioMenuItem value={PurchaseRequisitionTypes.SERVICES}>
												{t(
													`purchaseRequisitions.types.${PurchaseRequisitionTypes.SERVICES}`
												)}
											</NetcurioMenuItem>
										</OutlinedSelect>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<OutlinedTextField
										label={t('companyNameText')}
										value={user?.company?.name ?? ''}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<OutlinedTextField
										label={t('textRFC')}
										value={user?.company?.rfc ?? ''}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<OutlinedTextField
										label={t('purchaseRequisitions.detail.createdAt')}
										value={dayjs(new Date()).format('DD/MM/YYYY')}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={2} paddingTop={'2rem'} paddingRight={'1.5rem'}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="pr_currency"
											size="small"
											label={t('purchaseRequisitions.detail.currency')}
											disabled={headerFormDisabled}
										/>
										<OutlinedSelect
											label={t('purchaseRequisitions.detail.currency')}
											labelId={'pr_currency'}
											onChange={(e) => handleFieldChange(e, 'currency')}
											value={purchaseRequisition.currency}
											disabled={headerFormDisabled}
										>
											{currencies?.map((item, i) => {
												return (
													<CustomMenuItem value={item.code} key={i}>
														<span>{item.description}</span>
													</CustomMenuItem>
												)
											})}
										</OutlinedSelect>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={10} paddingTop={'2rem'}>
									<OutlinedTextField
										label={t('purchaseRequisitions.detail.description')}
										value={purchaseRequisition.description}
										onChange={(e) => handleFieldChange(e, 'description')}
										fullWidth
										disabled={headerFormDisabled}
										maxLength={CHARACTERS_LIMIT}
										helperText={
											purchaseRequisition.description === ''
												? t('max120Char')
												: t('remainingCharacters', {
														chars:
															CHARACTERS_LIMIT -
															(purchaseRequisition?.description?.length ?? 0)
													})
										}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid item container xs={12} marginTop={'2rem'}>
								<NetcurioGrid item container xs={12}>
									<div className={commonDetail.subtitleModule}>
										{t('purchaseRequisitions.detail.requesterInformation')}
									</div>
									<div className={commonDetail.dividingLine}></div>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<OutlinedTextField
										label={t('purchaseRequisitions.detail.createdBy')}
										value={
											purchaseRequisition.createdBy
												? `${purchaseRequisition.createdBy?.name} ${purchaseRequisition.createdBy?.lastname}`
												: ''
										}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="pr_branchOffice"
											size="small"
											label={t('purchaseRequisitions.detail.branch')}
											disabled={headerFormDisabled}
										/>
										<OutlinedSelect
											label={t('purchaseRequisitions.detail.branch')}
											labelId={'pr_branchOffice'}
											onChange={(e) => {
												handleFieldChange(e, 'branchOffice')
												handleFieldChange(e, 'branchOfficeId')
											}}
											value={purchaseRequisition.branchOffice ?? undefined}
											disabled={headerFormDisabled}
										>
											{branches?.map((item, i) => (
												<CustomMenuItem key={i} value={item.id}>
													<span>{item.description}</span>
												</CustomMenuItem>
											))}
										</OutlinedSelect>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={3} paddingRight={'1.5rem'}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="pr_requesterArea"
											size="small"
											label={t('purchaseRequisitions.detail.requesterArea')}
											disabled={headerFormDisabled}
										/>
										<OutlinedSelect
											label={t('purchaseRequisitions.detail.requesterArea')}
											labelId={'pr_requesterArea'}
											onChange={(e) => handleFieldChange(e, 'areaId')}
											value={purchaseRequisition.areaId}
											disabled={headerFormDisabled}
										>
											{areas?.map((item: Area, i: number) => (
												<CustomMenuItem key={i} value={item.id}>
													<span>{item.description}</span>
												</CustomMenuItem>
											))}
										</OutlinedSelect>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={3}>
									<NetcurioFormControl fullWidth>
										<Autocomplete
											label={t('purchaseRequisitions.detail.requester')}
											placeholder={t('purchaseRequisitions.detail.requester')}
											size="small"
											variant="outlined"
											options={suggestedUsers}
											value={userPreSavedObject}
											getOptionLabel={(option: User) =>
												`${option.name} ${option.lastname} - ${option.email}`
											}
											isOptionEqualToValue={(option: User, value: { email: string }) =>
												option?.email === value?.email
											}
											onSelectValue={(option: User) => {
												setPurchaseRequisition((prev) => ({
													...prev,
													requesterEmail: option?.email ?? ''
												}))
												setUserPreSavedObject(option)
											}}
											inputValue={userInputValue}
											onInputValueChange={getUserSuggestion}
											loading={userLoading}
											minLength={3}
											freeSolo
											fullWidth
											disabled={headerFormDisabled}
											getOptionKey={(option: User) => option?.email ?? ''}
										/>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioGrid item xs={12} marginTop={'2rem'} marginBottom={'2rem'}>
									<OutlinedTextField
										label={t('purchaseRequisitions.detail.address')}
										value={user?.company?.address_line_1 ?? ''}
										fullWidth
										disabled={true}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid item container xs={12} height="auto" paddingTop={'1.5rem'}>
						<NetcurioGrid item container xs={12} className={commonDetail.cardContainer}>
							<div className={commonDetail.cardTitle}>
								{t('purchaseRequisitions.detail.positions')}
							</div>
						</NetcurioGrid>
						<NetcurioGrid item container xs={12} className={commonDetail.positionsSection}>
							{purchaseRequisition.items.map(
								(item: PurchaseRequisitionItemInterface, key: number) => (
									<div key={key} style={{ width: '100%' }}>
										<PurchaseRequisitionPosition
											itemPosition={key}
											deleteItem={deletePosition}
											itemPR={item}
											selectProductPosition={selectProductPosition}
											totalErrors={totalErrorsInPositions}
											handlePositionFieldChange={handlePositionFieldChange}
											isFormDisabled={positionsAndButtonsDisabled}
											measurementUnits={measurementUnits}
											headerCurrency={purchaseRequisition.currency}
										/>
										<div className={commonDetail.dividingLinePositions}></div>
									</div>
								)
							)}
							<NetcurioButton
								startIcon={<NetcurioIcons.Add />}
								onClick={addPosition}
								variant="outlined"
								size={'small'}
								style={{ display: positionsAndButtonsDisabled ? 'none' : 'inherit' }}
							>
								{t('purchaseRequisitions.actions.addPosition')}
							</NetcurioButton>
						</NetcurioGrid>
					</NetcurioGrid>
				</NetcurioGrid>
				<NetcurioGrid item xs={3} paddingLeft={'1rem'}>
					<NetcurioGrid item paddingBottom={'1.5rem'} ref={totalContainerRef}>
						<TotalContainerBar
							title="total"
							fields={{
								currencyText: purchaseRequisition.currency
							}}
							total={{
								totalDotText: currencyFormatter.format(purchaseRequisition.total ?? 0)
							}}
						/>
					</NetcurioGrid>
					<ConditionalRender condition={!!purchaseID}>
						<NetcurioGrid
							item
							className={classNames(styles.commentSection)}
							paddingBottom={'1.5rem'}
							style={{
								minHeight: isCommentsSectionExtended
									? `calc(100vh - ${document.querySelector('header')?.offsetHeight ?? 0}px - ${totalContainerRef.current?.clientHeight ?? 0}px)`
									: 'auto'
							}}
						>
							<PurchaseRequisitionComments
								setIsExpanded={setIsCommentsSectionExtended}
								comments={comments}
								addComment={addComment}
							/>
						</NetcurioGrid>
					</ConditionalRender>
					<ConditionalRender
						condition={purchaseRequisition.status !== PurchaseRequisitionStatusEnum.NEW}
					>
						<NetcurioGrid
							item
							className={classNames(styles.commentSection)}
							paddingBottom={'1.5rem'}
						>
							<ApprovalFlowSection
								title={t('purchaseRequisitions.approvals.title')}
								subtitle={t('purchaseRequisitions.approvals.subtitle')}
								tableContent={approvalFlow}
							/>
						</NetcurioGrid>
					</ConditionalRender>
				</NetcurioGrid>
			</NetcurioGrid>
			<ErrorModal
				open={showErrorModal}
				errorCode={''}
				redirect={false}
				callClose={() => setShowErrorModal(false)}
			/>
		</NetcurioGrid>
	)
}
