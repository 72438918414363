import { NetcurioButton, NetcurioDialog, NetcurioIcons, TextArea } from '@netcurio/frontend-components'
import { useUpdateEffect } from '@netcurio/frontend-components/src/NetcurioList/hooks/useUpdateEffect'
import { useMutation } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionalRender } from '../../../../../components/ConditionalRender/ConditionalRender'
import { useClient } from '../../../../../hooks/useClient'
import { useUserRoles } from '../../../../../hooks/useUserRoles'
import { PurchaseRequisitionStatusEnum } from '../../../enums/purchase-requisition-status.enum'
import { REJECT_PURCHASE_REQUISITION } from '../../../graphql-ops/mutations'
import { useCurrentApprover } from '../../../hooks/useCurrentApprover'
import { PurchaseRequisitionInterface } from '../../../interfaces/purchase-requisition.interface'
import styles from './Reject.module.scss'

interface Props {
	purchaseRequisition: PurchaseRequisitionInterface
	onReject?: () => void
}

const CHARACTERS_LIMIT = 120

export const Reject = ({ purchaseRequisition, onReject }: Props) => {
	const { isApprover } = useUserRoles()
	const { t } = useTranslation()
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const client = useClient()
	const [hasError, setHasError] = useState<boolean>(false)

	const [comment, setComment] = useState<string>('')
	const { id, customerRfc } = purchaseRequisition

	const { isUserCurrentApprover } = useCurrentApprover({ purchaseRequisition })

	const canReject = useMemo(() => {
		const { status } = purchaseRequisition
		if (
			![PurchaseRequisitionStatusEnum.UNDER_REVIEW, PurchaseRequisitionStatusEnum.PENDING].includes(
				status as PurchaseRequisitionStatusEnum
			)
		) {
			return false
		}

		return isUserCurrentApprover
	}, [purchaseRequisition, isUserCurrentApprover])

	const { mutate: reject } = useMutation({
		mutationFn: async () => {
			const { data } = await client.mutate({
				mutation: REJECT_PURCHASE_REQUISITION,
				variables: {
					id,
					customerRfc: customerRfc,
					comment
				}
			})
			return data
		},
		onSuccess: () => {
			onReject?.()
			setIsModalOpen(false)
		},
		onError: (error) => {
			console.error('Error approving purchase requisition:', error)
		}
	})

	const handleOnSubmit = () => {
		if (comment.trim().length === 0) {
			setHasError(true)
		} else {
			setHasError(false)
			reject()
		}
	}
	useUpdateEffect(() => {
		if (isModalOpen) {
			setComment('')
			setHasError(false)
		}
	}, [isModalOpen])

	const remainingChars = () => {
		return CHARACTERS_LIMIT - comment.length === 1
			? t('invoice.remainingSingleCharacter')
			: t('invoice.remainingCharacters', {
					chars: CHARACTERS_LIMIT - comment.length
				})
	}

	if (
		!isApprover ||
		![PurchaseRequisitionStatusEnum.PENDING, PurchaseRequisitionStatusEnum.UNDER_REVIEW].includes(
			purchaseRequisition.status as PurchaseRequisitionStatusEnum
		) ||
		!canReject
	) {
		return null
	}

	return (
		<>
			<NetcurioButton
				color="primary"
				variant={'outlined'}
				onClick={() => setIsModalOpen(true)}
				size="small"
				className={styles.rejectButton}
				endIcon={<NetcurioIcons.Close />}
			>
				<span> {t('purchaseRequisitions.actions.reject')} </span>
			</NetcurioButton>

			<NetcurioDialog
				open={isModalOpen}
				actionButtons={
					<>
						<NetcurioButton onClick={() => setIsModalOpen(false)} variant="outlined">
							{t('comeBackTextModal')}
						</NetcurioButton>
						<NetcurioButton variant="contained" onClick={() => handleOnSubmit()}>
							{t('purchaseRequisitions.actions.reject')}
						</NetcurioButton>
					</>
				}
			>
				<p>{t('purchaseRequisitions.detail.rejectPurchaseRequisition')}</p>
				<div>
					<TextArea
						rows={3}
						placeholder={`${t('purchaseRequisitions.detail.rejectionReason')}*`}
						onChangeText={(response: string) => setComment(response)}
						readValue={comment}
						maxLength={CHARACTERS_LIMIT}
						errorMessage={hasError ? t('requiredField') : ''}
					/>
					<ConditionalRender condition={!hasError}>
						<span className={styles.textMaxChars}>{remainingChars()}</span>
					</ConditionalRender>
				</div>
			</NetcurioDialog>
		</>
	)
}
