import {
	dateFormatter,
	getSerieFolio,
	parseTimestampToTimeZone,
	Roles,
	SYMBOLS,
	URLS
} from '@netcurio/frontend-common'
import { NetcurioCheckbox, NetcurioChip, NetcurioIcons } from '@netcurio/frontend-components'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Constants from '../../../utilities/constants'
import Formatter from '../../../utilities/formatter'
import { getErrorDetail } from '../helpers/get-status-detail'
import styles from './invoicesList.module.scss'
import { TableRowProps } from './types'

export const InvoicesTableRow = ({
	informationTable: {
		uuid,
		serie,
		folio,
		status,
		total,
		payment_complements,
		proposed_payment_date,
		reference_type,
		consumption,
		purchase_order,
		sender,
		receiver,
		pinned,
		currency,
		error,
		sat_validation_message
	},
	userRoles = [],
	uuidInvoices,
	setUuidInvoices
}: TableRowProps) => {
	const { t } = useTranslation()
	const [checked, setChecked] = useState<boolean>(false)
	const isCustomer: boolean = userRoles.includes(Roles.CUSTOMER)
	const isConsumptionRelationship: boolean = reference_type === Constants.INVOICE.CONSUMPTION
	const customerReference: string = isConsumptionRelationship
		? consumption?.customer_reference
		: purchase_order?.customer_reference
	const isSupplier = userRoles.includes(Roles.SUPPLIER)
	const isMissingLink = status.key === Constants.LINKS.MISSING_LINK
	const isError = status.key === Constants.ERROR.ERROR
	const isException = status.key === Constants.ERROR.EXCEPTION

	const addInvoiceToArchive = () => {
		const tempSet: Set<string> = new Set(uuidInvoices)
		tempSet.add(uuid)
		setUuidInvoices(tempSet)
	}
	const deleteInvoiceToArchive = () => {
		const tempSetArchived: Set<string> = new Set(uuidInvoices)
		tempSetArchived.delete(uuid)
		setUuidInvoices(tempSetArchived)
	}

	const goTo = () => {
		if (isSupplier && (isMissingLink || isError || isException)) {
			return `${URLS.INVOICE_EDIT}?invoice=${uuid}`
		} else {
			return `${URLS.INVOICE_DETAIL}?invoice=${uuid}`
		}
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked)
		if (event.target.checked) addInvoiceToArchive()
		else deleteInvoiceToArchive()
	}

	const getStatusChip = (status: { key: string; value: string }) => {
		let statusSeverity:
			| 'default'
			| 'primary'
			| 'secondary'
			| 'error'
			| 'info'
			| 'success'
			| 'warning'
			| undefined = 'default'
		switch (status.key) {
			case Constants.STATUS.PAYMENT_SCHEDULED:
			case Constants.STATUS.PAID:
				statusSeverity = 'success'
				break
			case Constants.STATUS.EXCEPTION:
			case Constants.STATUS.MISSING_LINK:
				statusSeverity = 'warning'
				break
			case Constants.STATUS.IN_APPROVAL:
			case Constants.STATUS.IN_PROCESS:
				statusSeverity = 'info'
				break
			case Constants.STATUS.ERROR:
				statusSeverity = 'error'
				break
		}
		return <NetcurioChip label={status.value} severity={statusSeverity} />
	}

	const getStatusDetail = () => {
		if (status.key === Constants.STATUS.ERROR || status.key === Constants.STATUS.EXCEPTION) {
			return getErrorDetail(error?.key, sat_validation_message)?.title || SYMBOLS.HYPHEN
		}

		return SYMBOLS.HYPHEN
	}

	return (
		<div
			className={classNames(
				styles.containerRowList,
				checked ? styles.containerInvoicesCheckedArchived : ''
			)}
		>
			<div className={classNames(styles.fieldInvoices, styles.tableFieldCheckBoxContainer)}>
				<NetcurioCheckbox
					key={uuid}
					size="small"
					checked={checked}
					onChange={handleChange}
					inputProps={{ 'aria-label': 'controlled' }}
					className={classNames(checked ? styles.checked : '')}
				/>
				{pinned && (
					<div className={styles.pinIconContainer}>
						<NetcurioIcons.PushPin className={styles.pinIcon} />
					</div>
				)}
			</div>
			<Link to={goTo()} className={styles.gridAreaColumnRow}>
				<p className={styles.fieldInvoices}>{getSerieFolio(serie, folio)}</p>
				<div className={styles.fieldInvoices}>{getStatusChip(status)}</div>
				<p className={styles.fieldInvoices}>{getStatusDetail()}</p>
				<p className={styles.fieldInvoices}>{isCustomer ? sender?.name : receiver?.name}</p>
				<div className={styles.fieldInvoices}>
					{proposed_payment_date ? (
						dateFormatter.format(parseTimestampToTimeZone(proposed_payment_date))
					) : (
						<p>-</p>
					)}
				</div>
				<div className={styles.fieldInvoices}>{customerReference ? customerReference : <p>-</p>}</div>
				<div className={styles.fieldInvoices}>
					<div className={styles.serieFolioComplementInvoices}>
						<p>{payment_complements.length > 0 ? t('invoices.true') : t('invoices.false')}</p>
					</div>
				</div>
				<p className={styles.fieldInvoices}>{Formatter.currency.format(total)}</p>
				<p className={styles.fieldInvoices}>{currency}</p>
			</Link>
		</div>
	)
}
