import { NetcurioChip } from '@netcurio/frontend-components'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PurchaseRequisitionStatusEnum } from '../../enums/purchase-requisition-status.enum'
import { PurchaseRequisitionInterface } from '../../interfaces/purchase-requisition.interface'

const severityByStatus: Record<
	PurchaseRequisitionStatusEnum,
	'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
> = {
	[PurchaseRequisitionStatusEnum.NEW]: 'info',
	[PurchaseRequisitionStatusEnum.CANCELED]: 'default',
	[PurchaseRequisitionStatusEnum.APPROVED]: 'success',
	[PurchaseRequisitionStatusEnum.REJECTED]: 'default',
	[PurchaseRequisitionStatusEnum.PENDING]: 'warning',
	[PurchaseRequisitionStatusEnum.UNDER_REVIEW]: 'warning'
}

export const PurchaseRequisitionStatusChip = ({
	purchaseRequisition
}: {
	purchaseRequisition: PurchaseRequisitionInterface
}) => {
	const { status } = purchaseRequisition
	const { t } = useTranslation()

	const severity = useMemo(
		() => severityByStatus[status as PurchaseRequisitionStatusEnum] ?? 'default',
		[status]
	)

	return <NetcurioChip label={t(`purchaseRequisitions.status.${status}`)} severity={severity} />
}
