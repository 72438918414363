import { allowDecimals } from '@netcurio/frontend-common'
import {
	Autocomplete,
	CustomInputLabel,
	CustomMenuItem,
	NetcurioDatePicker,
	NetcurioFormControl,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioSelectChangeEvent,
	NetcurioTooltip,
	OutlinedSelect,
	OutlinedTextField
} from '@netcurio/frontend-components'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { ChangeEvent, FC, Key, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../../../common/styles/detail.module.scss'
import GlobalQueries from '../../../../components/queries'
import { MeasurementUnit, Product, Supplier } from '../../../../types'
import { connection } from '../../../../utilities/connection'
import { useDebounce } from '../../../../utilities/useDebounce'
import { PurchaseRequisitionItemInterface } from '../../interfaces/purchase-requisition-item.interface'
import { FieldValidations } from '../PurchaseRequisitionDetail'

interface ItemProps {
	itemPosition: number
	deleteItem: (itemPosition: number) => void
	handlePositionFieldChange: (
		value: string | number | ChangeEvent<HTMLInputElement> | Supplier,
		index: number,
		nameField: string
	) => void
	itemPR: PurchaseRequisitionItemInterface
	selectProductPosition: (
		product: Product | undefined,
		itemPosition: number,
		unitPrice: string | number,
		unit: string,
		referencePrice: number,
		supplier: Supplier | undefined,
		currency: string
	) => void
	totalErrors: Array<FieldValidations>
	isFormDisabled: boolean
	measurementUnits: Array<MeasurementUnit>
	headerCurrency?: string
}

export const PurchaseRequisitionPosition: FC<ItemProps> = ({
	itemPosition,
	selectProductPosition,
	itemPR,
	handlePositionFieldChange,
	deleteItem,
	totalErrors,
	isFormDisabled,
	measurementUnits,
	headerCurrency
}) => {
	const { t } = useTranslation()
	const client = connection()
	const minDate = dayjs()

	const [prodLoading, setProdLoading] = useState<boolean>(false)
	const [supplierLoading, setSupplierLoading] = useState<boolean>(false)
	const [supplierInputValue, setSupplierInputValue] = useState<string>('')
	const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined)
	const [selectedSupplier, setSelectedSupplier] = useState<Supplier | undefined>(undefined)
	const [currentProductsList, setCurrentProductsList] = useState<Product[]>([])
	const [suppliersList, setSuppliersList] = useState<Array<Supplier>>([])
	const [supplierPreSavedObject, setSupplierPreSavedObject] = useState<Supplier | undefined>(undefined)
	const [requestedAmount, setRequestedAmount] = useState<number>(0)

	const [itemNetPrice, setItemNetPrice] = useState<number>(itemPR.netAmount ?? 0)
	const [itemUnitMeasure, setItemUnitMeasure] = useState<string>(itemPR.unit ?? '')
	const [itemUnitPrice, setItemUnitPrice] = useState<number>(itemPR.unitPrice ?? 0)
	const [productInputValue, setProductInputValue] = useState<string>(itemPR.description ?? '')
	const [date, setDate] = useState<Dayjs | dayjs.Dayjs | undefined>(
		dayjs(itemPR.requiredDeliveryDate) ?? undefined
	)
	const [supplierSearchString, setSupplierSearchString] = useState<string>(itemPR.supplierRfc ?? '')

	const debouncedProductInput = useDebounce<string>(productInputValue, 500)
	const debouncedSupplierInput = useDebounce<string>(supplierSearchString, 500)
	const errorsInPurchaseRequisition = totalErrors[itemPosition]

	useEffect(() => {
		if (itemPR) {
			setSupplierSearchString(itemPR.supplierRfc ?? '')
			setProductInputValue(itemPR.description ?? '')
			setItemUnitMeasure(itemPR.unit ?? '')
			setItemNetPrice(itemPR.netAmount ?? 0)
			setItemUnitPrice(itemPR.unitPrice ?? 0)
			setDate(dayjs(itemPR.requiredDeliveryDate))
		}
	}, [itemPR])
	useEffect(() => {
		if (debouncedSupplierInput.length >= 3) {
			setSupplierLoading(true)
			suggestSupplier(debouncedSupplierInput)
		}
	}, [debouncedSupplierInput])

	useEffect(() => {
		if (selectedSupplier?.rfc || (itemPR && itemPR.supplierRfc)) {
			if (debouncedProductInput.length >= 3) {
				setProdLoading(true)
				client
					.query({
						query: GlobalQueries.PRODUCT_NAME_CODE,
						variables: {
							searchText: debouncedProductInput.toLowerCase(),
							supplier: selectedSupplier?.rfc ?? itemPR.supplierRfc
						}
					})
					.then((result) => {
						setCurrentProductsList(result.data.ProductsByNameOrCode)
						if (itemPR.supplierRfc && itemPR) {
							const foundItem = result.data.ProductsByNameOrCode.find(
								(product: { id: string | undefined }) => {
									return product.id === itemPR.code
								}
							)
							selectProduct(foundItem)
						}
					})
					.catch((error) => {
						console.error(error)
					})
					.finally(() => setProdLoading(false))
			}
		} else {
			setCurrentProductsList([])
		}
	}, [debouncedProductInput])

	const getProductSuggestion = (searchText: string) => {
		setProductInputValue(searchText)
	}

	const validateProductValue = (option: unknown, value: unknown) => {
		const product = option as Product
		const selection = value as Product
		return product.id === selection.id
	}

	const getProductLabel = (option: unknown) => {
		const product = option as Product
		return `${product.id} - ${product.description}`
	}

	const getSupplierLabel = (option: Supplier) => {
		return `${option.name} - ${option.rfc}`
	}

	const validateSupplierValue = (option: Supplier, value: { rfc: string }) => option?.rfc === value?.rfc

	const handleSupplierSelection = (supplier: Supplier) => {
		if (supplier) {
			const chosenSupplier: Supplier | undefined = suppliersList?.find((supp) => supp === supplier)
			if (chosenSupplier) {
				setSelectedSupplier(chosenSupplier)
				handlePositionFieldChange(chosenSupplier, itemPosition, 'supplier')
			}
		} else {
			setSelectedSupplier(undefined)
			setSelectedProduct(undefined)
		}
	}
	const getSupplierSuggestion = (searchText: string) => {
		setSupplierSearchString(searchText)
		setSupplierInputValue(searchText)
	}

	const suggestSupplier = (debouncedSupplierInput: string) => {
		client
			.query({
				query: GlobalQueries.SUPPLIERS_BY_NAME_OR_CODE,
				variables: {
					search_text: debouncedSupplierInput
				}
			})
			.then((result: { data: { SuppliersByNameOrCode: any } }) => {
				setSuppliersList(result.data.SuppliersByNameOrCode)
				if (itemPR.supplierRfc && itemPR && !supplierPreSavedObject) {
					setSupplierPreSavedObject(result.data.SuppliersByNameOrCode[0])
					setRequestedAmount(itemPR.quantity ? +itemPR.quantity : 0)
				}
				setSupplierLoading(false)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const selectProduct = (option: any) => {
		const product = option as Product
		if (product) {
			setSelectedProduct(product)
			if (!itemPR.code) {
				const isCurrencyMatchingHeader = product.currency && product.currency === headerCurrency
				selectProductPosition(
					product,
					itemPosition,
					product.unit_price ? product.unit_price : 0,
					product.unit ? product.unit : '',
					product.unit_price ? product.unit_price : 0,
					selectedSupplier ? selectedSupplier : undefined,
					isCurrencyMatchingHeader ? (product.currency ?? '') : ''
				)
				setItemUnitMeasure(product.unit ?? '')
				setItemNetPrice(product.unit_price ? product.unit_price : 0)
				setItemUnitPrice(isCurrencyMatchingHeader ? (product.unit_price ?? 0) : 0)
			}
		}
	}

	const setQuantity = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === '.') {
			event.target.value = '0.'
		}
		if (allowDecimals(event.target.value)) {
			handlePositionFieldChange(event.target.value, itemPosition, 'quantity')
			if (event.target.value) {
				setRequestedAmount(Number(event.target.value))
			}
		}
	}

	const setUnitPrice = (event: ChangeEvent<HTMLInputElement>) => {
		if (allowDecimals(event.target.value)) {
			handlePositionFieldChange(event.target.value, itemPosition, 'unitPrice')
			setItemUnitPrice(+event.target.value)
		}
	}

	const handleSetUnitMeasure = (event: NetcurioSelectChangeEvent) => {
		handlePositionFieldChange(event.target.value, itemPosition, 'measurementUnit')
		setItemUnitMeasure(event.target.value)
	}

	const handleOnChangeDate = (date: Dayjs) => {
		setDate(date)
		handlePositionFieldChange(date?.toISOString(), itemPosition, 'requiredDate')
	}

	const displayWarningMsg = () => {
		if (
			errorsInPurchaseRequisition?.quantity ||
			errorsInPurchaseRequisition?.product ||
			errorsInPurchaseRequisition?.unit_measure ||
			errorsInPurchaseRequisition?.unit_cost ||
			errorsInPurchaseRequisition?.required_date
		) {
			return <NetcurioGrid className={styles.errorText}>{t('fieldNoFillText')}</NetcurioGrid>
		} else {
			return null
		}
	}

	return (
		<NetcurioGrid className={styles.itemModule}>
			<NetcurioGrid className={styles.rightModule}>
				<NetcurioGrid className={styles.alignModules}>
					<NetcurioTooltip title={t('positionText')}>
						<div
							className={classNames(
								styles.readOnlyField,
								styles.resizePosition,
								styles.positionHeight
							)}
						>
							{itemPosition + 1}
						</div>
					</NetcurioTooltip>
					<NetcurioGrid style={{ width: '100%' }}>
						<NetcurioGrid className={styles.containerSize}>
							<NetcurioGrid className={styles.flexContainer}>
								<NetcurioGrid className={styles.supplierContainer}>
									<Autocomplete
										label={t('supplierText')}
										placeholder={t('selectSupplier')}
										size="small"
										variant="outlined"
										options={suppliersList}
										getOptionLabel={getSupplierLabel}
										isOptionEqualToValue={validateSupplierValue}
										value={selectedSupplier}
										onSelectValue={handleSupplierSelection}
										inputValue={supplierInputValue}
										onInputValueChange={getSupplierSuggestion}
										loading={supplierLoading}
										minLength={3}
										freeSolo
										fullWidth
										disabled={isFormDisabled}
										error={
											errorsInPurchaseRequisition
												? errorsInPurchaseRequisition.supplier
												: false
										}
										getOptionKey={(option: any) => option?.rfc ?? ''}
										key={
											itemPR && itemPR.supplierRfc
												? (supplierPreSavedObject as unknown as Key)
												: undefined
										}
										defaultValue={
											itemPR && itemPR.supplierRfc
												? (supplierPreSavedObject as unknown as Key)
												: null
										}
									/>
								</NetcurioGrid>
								<NetcurioGrid className={styles.resizeProduct}>
									<Autocomplete
										label={t('productText')}
										placeholder={t('selectProduct')}
										size="small"
										variant="outlined"
										options={currentProductsList}
										getOptionLabel={getProductLabel}
										isOptionEqualToValue={validateProductValue}
										value={selectedProduct}
										onSelectValue={selectProduct}
										inputValue={productInputValue}
										onInputValueChange={getProductSuggestion}
										loading={prodLoading}
										minLength={3}
										freeSolo
										fullWidth
										disabled={isFormDisabled}
										error={
											errorsInPurchaseRequisition
												? errorsInPurchaseRequisition.product
												: false
										}
										getOptionKey={(option: any) => option?.id ?? ''}
										key={
											itemPR && itemPR.description
												? (selectedProduct as unknown as Key)
												: null
										}
										defaultValue={itemPR && itemPR.description ? selectedProduct : null}
									/>
								</NetcurioGrid>
							</NetcurioGrid>
							<NetcurioGrid
								className={classNames(styles.flexContainer, styles.marginRowPosition)}
							>
								<NetcurioTooltip title={t('quantity')}>
									<NetcurioGrid className={styles.resizeUm}>
										<OutlinedTextField
											label={t('quantity')}
											value={itemPR.quantity ? itemPR.quantity : requestedAmount}
											onChange={setQuantity}
											error={
												errorsInPurchaseRequisition
													? errorsInPurchaseRequisition.quantity
													: false
											}
											fullWidth
											disabled={isFormDisabled}
										/>
									</NetcurioGrid>
								</NetcurioTooltip>
								<NetcurioGrid className={styles.resizeUm}>
									<NetcurioFormControl fullWidth>
										<CustomInputLabel
											id="unitMeasure"
											label={t('purchaseRequisitions.detail.unit')}
											size="small"
											disabled={isFormDisabled}
										/>
										<OutlinedSelect
											labelId={'unitMeasure'}
											label={t('unitMeasureText')}
											value={itemUnitMeasure}
											onChange={(e) => handleSetUnitMeasure(e)}
											error={
												errorsInPurchaseRequisition
													? errorsInPurchaseRequisition.unit_measure
													: false
											}
											disabled={isFormDisabled}
										>
											{measurementUnits?.map((measurementUnit) => {
												return (
													<CustomMenuItem
														value={measurementUnit.code}
														key={measurementUnit.code}
													>
														{measurementUnit.description}
													</CustomMenuItem>
												)
											})}
										</OutlinedSelect>
									</NetcurioFormControl>
								</NetcurioGrid>
								<NetcurioTooltip title={t('purchaseRequisitions.detail.unitPrice')}>
									<NetcurioGrid className={styles.productSpace}>
										<OutlinedTextField
											label={t('purchaseRequisitions.detail.unitPrice')}
											value={itemUnitPrice}
											onChange={setUnitPrice}
											adornment={
												itemUnitPrice ? <NetcurioIcons.AttachMoney /> : undefined
											}
											fullWidth
											adornmentPosition={'start'}
											error={
												errorsInPurchaseRequisition
													? errorsInPurchaseRequisition.unit_cost
													: false
											}
											disabled={isFormDisabled}
										/>
									</NetcurioGrid>
								</NetcurioTooltip>
								<NetcurioTooltip title={t('netValue')}>
									<NetcurioGrid className={styles.productSpace}>
										<OutlinedTextField
											label={t('netValue')}
											value={itemNetPrice}
											adornment={<NetcurioIcons.AttachMoney />}
											adornmentPosition="start"
											disabled
											fullWidth
										/>
									</NetcurioGrid>
								</NetcurioTooltip>
								<NetcurioTooltip title={t('purchaseRequisitions.detail.requiredDate')}>
									<NetcurioGrid>
										<NetcurioDatePicker
											height="smaller"
											label={t('requiredDateText')}
											format="DD/MM/YY"
											value={date}
											onChange={(date: Dayjs) => handleOnChangeDate(date)}
											minDate={minDate}
											error={
												errorsInPurchaseRequisition
													? errorsInPurchaseRequisition.required_date
													: false
											}
											fullWidth
											disabled={isFormDisabled}
										/>
									</NetcurioGrid>
								</NetcurioTooltip>
							</NetcurioGrid>
							{displayWarningMsg()}
						</NetcurioGrid>
					</NetcurioGrid>
					<NetcurioGrid>
						<NetcurioTooltip title={t('delete')}>
							<NetcurioIcons.Close
								style={{ display: isFormDisabled ? 'none' : 'inherit' }}
								className={styles.deletePositionIcon}
								onClick={() => deleteItem(itemPosition)}
							/>
						</NetcurioTooltip>
					</NetcurioGrid>
				</NetcurioGrid>
			</NetcurioGrid>
		</NetcurioGrid>
	)
}
