import { Roles, tableFillSelectionFilter } from '@netcurio/frontend-common'
import Constants from '../../utilities/constants'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './requestForCreditMemoList.module.scss'

export function objectHeaderArray(userRoles: Roles[]): Array<tableFillHeader> {
	const idRequestForCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'billText',
		styles.headerStandardSizeLeftRadius,
		'',
		'id',
		'text'
	)
	const customerReferenceRequestForCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'customerReferenceRFCMtHeader',
		styles.headerStandardSize,
		'',
		'customer_reference',
		'text'
	)
	const statusRequestForCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'statusHeader',
		styles.headerStandardSize,
		'',
		'status',
		'status'
	)
	const createdByRequestForCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'createdByText',
		styles.headerStandardSize,
		'',
		'created_by',
		'text'
	)
	const supplierRequestForCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'supplierRFCMHeader',
		styles.headerLargerSize,
		'',
		'supplier',
		'text'
	)
	const createdAtRequestForCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'creationDateHeader',
		styles.headerSmallSizeRightRadius,
		'',
		'created_at',
		'date'
	)
	const modifiedByRequestForCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'modifiedBy',
		styles.headerStandardSize,
		'',
		'modified_by_supplier',
		'text'
	)
	const customerRequestForCreditMemoColumn: tableFillHeader = new tableFillHeader(
		'customerText',
		styles.headerLargerSize,
		'',
		'customer',
		'text'
	)

	const objectHeaderArrayCustomer: Array<tableFillHeader> = [
		idRequestForCreditMemoColumn,
		customerReferenceRequestForCreditMemoColumn,
		statusRequestForCreditMemoColumn,
		createdByRequestForCreditMemoColumn,
		supplierRequestForCreditMemoColumn,
		createdAtRequestForCreditMemoColumn
	]
	const objectHeaderArraySupplier: Array<tableFillHeader> = [
		idRequestForCreditMemoColumn,
		customerReferenceRequestForCreditMemoColumn,
		statusRequestForCreditMemoColumn,
		modifiedByRequestForCreditMemoColumn,
		customerRequestForCreditMemoColumn,
		createdAtRequestForCreditMemoColumn
	]

	if (userRoles.includes(Roles.CUSTOMER)) {
		return objectHeaderArrayCustomer
	} else {
		return objectHeaderArraySupplier
	}
}

export function objectStatusFilterArray(): Array<tableFillSelectionFilter> {
	const newStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.STATUS.NEW,
		'new-status-button'
	)
	const acceptedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.STATUS.ACCEPTED,
		'accepted-status-button'
	)
	const rejectedStatusOption: tableFillSelectionFilter = new tableFillSelectionFilter(
		Constants.STATUS.REJECTED,
		'reject-status-button'
	)

	return [newStatusOption, acceptedStatusOption, rejectedStatusOption]
}
