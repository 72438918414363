import { Comment } from '@netcurio/frontend-common'
import { CommentSectionSize, CommentSectionVariant, CommentsSection } from '@netcurio/frontend-components'
import React from 'react'

interface PurchaseRequisitionCommentsProps {
	comments: Comment[]
	setIsExpanded?: (isExpanded: boolean) => void
	addComment: (comment: string) => void
}

export const PurchaseRequisitionComments = ({
	setIsExpanded,
	comments = [],
	addComment
}: PurchaseRequisitionCommentsProps) => {
	return (
		<CommentsSection
			comments={comments}
			variant={CommentSectionVariant.WithBody}
			onAddComment={(comment) => {
				const { text } = comment
				addComment(String(text))
			}}
			onSizeChange={(commentsSectionSize: CommentSectionSize) => {
				setIsExpanded?.(commentsSectionSize === CommentSectionSize.Extended)
			}}
		/>
	)
}
