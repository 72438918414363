import { FilterInput } from '@netcurio/frontend-common/src'
import { useNetcurioLoader } from '@netcurio/frontend-components'
import { useState } from 'react'
import { useClient } from '../../../../hooks/useClient'
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll'
import { PURCHASE_REQUISITIONS } from '../../graphql-ops/queries'
import { PurchaseRequisitionInterface } from '../../interfaces/purchase-requisition.interface'

export const usePurchaseRequisitions = () => {
	const client = useClient()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()

	const [isFirstRender, setIsFirstRender] = useState(true)

	const fetchPurchaseRequisitions = async ({
		skip,
		limit,
		filters,
		sortField,
		sortOrder
	}: {
		skip: number
		limit: number
		filters: Record<string, any>
		sortField: string
		sortOrder: string
	}): Promise<{ total: number; items: PurchaseRequisitionInterface[] }> => {
		try {
			if (isFirstRender) {
				showLoadingSpinner()
			}
			const response = await client.query({
				query: PURCHASE_REQUISITIONS,
				variables: { skip, limit, filter: filters, sortField, sortOrder },
				fetchPolicy: 'no-cache'
			})

			return {
				total: response.data.PurchaseRequisitions.total,
				items: response.data.PurchaseRequisitions.list
			}
		} catch (error) {
			console.error('Error fetch data:', error)
			return {
				total: 0,
				items: []
			}
		} finally {
			if (isFirstRender) {
				hideLoadingSpinner()
				setIsFirstRender(false)
			}
		}
	}

	const {
		items: purchaseRequisitions,
		hasMore,
		loading,
		loadMore,
		reload,
		setFilters,
		setSortParams
	} = useInfiniteScroll<PurchaseRequisitionInterface>({
		fetchFn: fetchPurchaseRequisitions,
		initialLimit: 22,
		initialSkip: 0,
		defaultSortField: 'createdAt',
		defaultSortOrder: 'DESC'
	})

	const [selectedPurchaseRequisitions, setSelectedPurchaseRequisitions] = useState<
		PurchaseRequisitionInterface[]
	>([])

	const handleOnFiltersChange = (filters: FilterInput[]) => {
		setFilters(
			filters.map((filter) =>
				filter.type === 'date'
					? {
							...filter,
							initDate: filter?.initDate?.toDate().getTime(),
							finalDate: filter?.finalDate?.toDate().getTime()
						}
					: filter.type === 'numeric'
						? {
								...filter,
								initRange: filter?.initRange ? String(filter?.initRange) : undefined,
								finalRange: filter?.finalRange ? String(filter?.finalRange) : undefined
							}
						: filter
			)
		)
	}

	const handleOnChangeSort = (field: string, order: string) => {
		setSortParams({ field, order })
	}

	return {
		purchaseRequisitions,
		selectedPurchaseRequisitions,
		setSelectedPurchaseRequisitions,
		hasMore,
		loading,
		reload,
		loadMore,
		handleOnFiltersChange,
		handleOnChangeSort
	}
}
