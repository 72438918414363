import { URLS } from '@netcurio/frontend-common'
import { Auth } from 'aws-amplify'
import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import { refreshToken } from '../utilities/connection'
import Constants from '../utilities/constants'
import { ExpiredSessionModal } from './dialogModal/expiredSessionModal'
import { fillModal } from './dialogModal/fillModal'

let intervalShowModal: number
let root: Root

function acceptActionModal() {
	refreshToken()
	const element = document.getElementById('parentModalExpiredSession')
	if (element) root.unmount()
}

function hideModal() {
	Auth.signOut()
		.then(() => {
			localStorage.clear()
			location.href = URLS.LOGIN
		})
		.catch((err) => console.log(err))
}

function getExpiredSessionModalProps() {
	const constructorProps = {
		actionModal: 'expiredSession',
		textTitle: 'expireSession',
		textQuestion: 'expireSessionText',
		actionAccept: 'session',
		textAction: 'continueText',
		textCancel: 'exitText',
		contentTitle: ''
	}
	const expiredSession = new fillModal(
		constructorProps.actionModal,
		constructorProps.textTitle,
		constructorProps.textQuestion,
		constructorProps.actionAccept,
		constructorProps.textAction,
		constructorProps.textCancel,
		''
	)
	return [expiredSession]
}

function showModal(timeOutToken: number) {
	const parentModal = document.getElementById('parentModalExpiredSession')
	const modalProps = getExpiredSessionModalProps()
	const element = (
		<ExpiredSessionModal
			fillModal={modalProps}
			acceptActionModal={acceptActionModal}
			hideModal={hideModal}
			timeOutToken={timeOutToken}
		/>
	)
	if (parentModal) {
		root = createRoot(parentModal)
		root.render(element)
	}
}

export function showModalExpiredSession() {
	Auth.currentAuthenticatedUser()
		.then((user) => {
			clearInterval(intervalShowModal)
			const timeOutTokenModal = (user.signInUserSession.idToken.payload.iat + 14 * 60) * 1000
			const timeOutTokenLogOut =
				(user.signInUserSession.idToken.payload.iat + Constants.AUTH.TOKEN_VALIDITY_MINUTES * 60) *
				1000
			intervalShowModal = window.setInterval(function () {
				const now = new Date()
				if (timeOutTokenLogOut < now.getTime()) {
					clearInterval(intervalShowModal)
					refreshToken(Constants.ERROR.INVALID_TOKEN)
				} else if (timeOutTokenModal < now.getTime()) {
					clearInterval(intervalShowModal)
					showModal(timeOutTokenLogOut)
				}
			}, 1000)
		})
		.catch((err) => {
			console.error(err)
		})
}
