import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { GET_PURCHASE_REQUISITION_DETAIL } from '../graphql-ops/queries'
import { PurchaseRequisitionInterface } from '../interfaces/purchase-requisition.interface'

export const triggerGetPurchaseRequisitionDetail = async (
	client: DefaultClient<NormalizedCacheObject>,
	id?: number,
	customerRfc?: string
): Promise<PurchaseRequisitionInterface | 'Error'> => {
	try {
		const response = await client.query({
			query: GET_PURCHASE_REQUISITION_DETAIL,
			variables: { id: id, customerRfc: customerRfc },
			fetchPolicy: 'no-cache'
		})
		return response.data.PurchaseRequisition
	} catch (e) {
		console.log(e)
		return 'Error'
	}
}
