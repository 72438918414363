import { AUTH, RELATION_KEY_URL, URLS, getCurrentUser, getDefaultAccessPage } from '@netcurio/frontend-common'
import Tracker from '@openreplay/tracker'
import { Auth } from 'aws-amplify'
import { useEffect, useMemo } from 'react'
import ERROR from '../utilities/constants/error'
import { validateMultiCompany } from '../utilities/redirectHelper'
import UserInformation from '../utilities/userInformation'
import { useEnvironment } from './useEnvironment'

interface AuthProps {
	onTokenExpired?: () => void
}

export const useAuth = ({ onTokenExpired }: AuthProps = {}) => {
	const { environment, isSuccess } = useEnvironment()
	let trackerId = undefined

	if (isSuccess) {
		trackerId = environment.OPENREPLAY_KEY
	}
	const tracker = useMemo(() => {
		return trackerId
			? new Tracker({
					projectKey: trackerId,
					network: {
						sessionTokenHeader: false,
						failuresOnly: false,
						ignoreHeaders: true,
						capturePayload: true, //start capturing the payload of every request
						sanitizer: (data) => {
							// ignore payloads for calls that start with /secure
							if (data.url.startsWith('/secure')) {
								data.request.body = null
								data.response.body = null
							}
							if (data.request.headers['Authorization']) {
								// can also use ignoreHeaders option instead
								data.request.headers['Authorization'] = 'SANITISED'
							}
							return data
						}
					},
					onStart: ({ sessionID }: { sessionID: string }) =>
						console.log('OpenReplay tracker started with session: ', sessionID)
				})
			: undefined
	}, [trackerId])

	const tokenHasExpired = (idToken: { payload: { iat: number } }) => {
		const now = new Date()
		const issuedAt = idToken.payload.iat
		const expiresOn = (issuedAt + AUTH.TOKEN_VALIDITY_MINUTES * 60) * 1000
		return expiresOn < now.getTime()
	}

	useEffect(() => {
		const currentUser = getCurrentUser()
		if (tracker && currentUser && currentUser.email) {
			tracker.start()
		}
	}, [tracker])

	const signOut = () => {
		Auth.signOut()
			.then(() => {
				localStorage.clear()
				location.href = URLS.LOGIN
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				if (tokenHasExpired(user.signInUserSession.idToken)) {
					if (window.location.pathname !== URLS.LOGIN) {
						signOut()
					}
					onTokenExpired?.()
					return
				}
				const currentUser = getCurrentUser()
				if (!currentUser) {
					signOut()
					return
				}
				let companySelected = currentUser.company
				const companiesUnblocked = UserInformation.getCompaniesUnblocked()
				const userInfo = currentUser
				const haveDefaultCompany = UserInformation.getDefaultCompany()
				const appURLS: { [key: string]: string } = URLS
				let urlValid = false

				if (tracker && currentUser && currentUser.email) {
					tracker.setUserID(currentUser.email)
				}

				const pathname = window.location.pathname

				for (const key in appURLS) {
					if (appURLS[key] === pathname) {
						urlValid = true
						break
					}
				}
				if (
					!urlValid &&
					(pathname.includes(appURLS.ADMINISTRATION + '/') ||
						pathname.includes(appURLS.REPORT_LIST + '/') ||
						pathname.includes(appURLS.SAMPLE + '/'))
				) {
					urlValid = true
				}

				if (!urlValid) {
					location.href = appURLS.NOT_FOUND
					return
				}
				switch (pathname) {
					case '/':
					case appURLS.LOGIN:
					case appURLS.REGISTER:
					case appURLS.RESET_PASSWORD:
					case appURLS.PASS_RESET_SUCCESSFUL:
					case appURLS.PASSWORD_RECOVERY:
						if (companySelected) {
							location.href = companySelected.homepage
								? appURLS[
										RELATION_KEY_URL[
											companySelected.homepage as keyof typeof RELATION_KEY_URL
										]
									]
								: (getDefaultAccessPage(currentUser) ?? appURLS.PO_LIST)
						} else {
							if (haveDefaultCompany) {
								companySelected = UserInformation.getCompanySelected(
									companiesUnblocked,
									haveDefaultCompany ?? ''
								)
								if (companySelected !== undefined) {
									UserInformation.setCurrentUser(userInfo, companySelected)
									location.href = companySelected.homepage
										? appURLS[companySelected.homepage]
										: (getDefaultAccessPage(currentUser) ?? appURLS.PO_LIST)
								} else {
									validateMultiCompany(companiesUnblocked, undefined)
								}
							} else {
								validateMultiCompany(companiesUnblocked, undefined)
							}
						}
				}
			})
			.catch((err) => {
				if (err !== ERROR.AWS_NOT_AUTHENTICATED_ERROR) {
					console.error(err)
				}
				const pathname = window.location.pathname
				if (
					pathname !== URLS.LOGIN &&
					pathname !== URLS.REGISTER &&
					pathname !== URLS.RESET_PASSWORD &&
					pathname !== URLS.PASSWORD_RECOVERY &&
					pathname !== URLS.PASS_RESET_SUCCESSFUL &&
					pathname !== URLS.REGISTER_COMPANY &&
					pathname !== '/'
				) {
					sessionStorage.setItem('internalUrl', window.location.href)
					location.href = URLS.LOGIN
				}
			})
	}, [onTokenExpired, tracker])
}
