import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextFieldRenderInputParams from '@mui/material/TextField'
import {
	Netcurio01PrimaryBDark,
	Netcurio01PrimaryCLight,
	Netcurio03TextADarkAHighEmphasisTextPrimary,
	Netcurio03TextADarkCLowEmphasisDisabled,
	Netcurio04ActionCDisabled,
	Netcurio04BodyBody2ADarkAHighEmphasisText,
	Netcurio05ErrorAMain,
	Netcurio06WarningCLight
} from '@netcurio/frontend-design-tokens'
import React, { forwardRef, ReactNode } from 'react'
import { CustomInputLabel } from '../CustomInputLabel/CustomInputLabel'

export interface OutlinedTextFieldProps extends Partial<typeof TextFieldRenderInputParams> {
	label: string
	variant?: 'standard' | 'filled' | 'outlined'
	size?: 'small' | undefined
	value?: unknown
	disabled?: boolean
	error?: boolean
	type?: 'email' | 'password'
	adornment?: ReactNode
	adornmentPosition?: 'end' | 'start'
	fullWidth?: boolean
	helperText?: ReactNode
	defaultValue?: string
	warning?: boolean
	maxLength?: number
	minLength?: number
	autoFocus?: boolean
	placeholder?: string
	hiddenLabel?: boolean
	id?: string
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
}

/**
 * OutlinedTextField
 * @param label <ReactNode>: The label content.
 * @param variant: <'standard' | 'filled' | 'outlined'>: The variant to use. @default 'outlined'
 * @param size: <'small' | undefined>: The size to use. @default 'small' (3.2rem)
 * @param value <unknown>: The value of the `input` element, required for a controlled component
 * @param disabled <boolean>: If `true`, the component is disabled
 * @param error <boolean>: If `true`, the label is displayed in an error state
 * @param type <undefined | 'password'>: Set to 'password' to use a password field
 * @param adornment <ReactNode> Expects a MUI Icon to use as adornment
 * @param adornmentPosition <'end'|'start'> Position of the adornment inside the input
 * @param fullWidth <boolean>: If `true`, the input will take up the full width of its container
 * @param helperText <ReactNode>: The helper text content. Also, useful in validation
 * @param onChange <(event: React.ChangeEvent<HTMLInputElement>) => void>: Callback fired when the value is changed
 * @param id <string>: The ID of the TextField.
 * @returns component
 */
export const OutlinedTextField = forwardRef<HTMLDivElement, OutlinedTextFieldProps>((props, ref) => {
	const {
		label,
		variant = 'outlined',
		size = 'small',
		value,
		disabled,
		error,
		adornment,
		adornmentPosition,
		warning,
		maxLength,
		onChange,
		onBlur,
		onFocus,
		onKeyDown,
		id,
		...rest
	} = props

	const borderColor = () => {
		if (disabled) {
			return Netcurio04ActionCDisabled
		} else if (error) {
			return Netcurio05ErrorAMain
		} else if (warning) {
			return Netcurio06WarningCLight
		} else return Netcurio01PrimaryCLight
	}

	const hoverBorderColor = () => {
		if (disabled) {
			return Netcurio04ActionCDisabled
		} else if (error) {
			return Netcurio05ErrorAMain
		} else if (warning) {
			return Netcurio06WarningCLight
		} else return Netcurio01PrimaryBDark
	}

	const styles = {
		'& .MuiInputBase-root': {
			height: '3.2rem'
		},
		'& .MuiInputBase-input': {
			padding: adornment ? '0.6rem 1rem 0.6rem 0' : '0.6rem 1rem 0.6rem 1rem',
			position: 'relative',
			font: Netcurio04BodyBody2ADarkAHighEmphasisText,
			color: disabled
				? Netcurio03TextADarkCLowEmphasisDisabled
				: Netcurio03TextADarkAHighEmphasisTextPrimary
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: borderColor()
		},
		'& .MuiOutlinedInput-root ': {
			paddingLeft: 0,
			paddingRight: 0,
			fontSize: '1.2rem',
			letterSpacing: '0.01rem',
			'& fieldset': {
				border: '0.1rem solid',
				borderColor: warning ? Netcurio06WarningCLight : borderColor()
			},
			'&:hover fieldset': {
				border: '0.1rem solid',
				borderColor: hoverBorderColor()
			},

			'& input:focus + fieldset': {
				border: '0.2rem solid',
				borderColor: hoverBorderColor()
			}
		},
		// Focused effect for adornment-end case
		'& .MuiInputBase-adornedEnd': {
			paddingLeft: '1rem',
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				border: '0.2rem solid',
				borderColor: hoverBorderColor()
			}
		},
		// HELPER TEXT
		'& .MuiFormHelperText-root': {
			fontSize: '1rem'
		},
		// ADORNMENT
		'& .MuiInputAdornment-root': {
			marginRight: 0,
			marginLeft: 0,
			padding: '0 0.5rem'
		}
	}
	const TextFieldInputLabel = React.forwardRef(() => {
		return (
			<CustomInputLabel
				label={label}
				id={id}
				size={'small'}
				error={error}
				warning={warning}
				disabled={disabled}
			/>
		)
	})
	return (
		<TextField
			{...rest}
			id={id}
			ref={ref}
			label={label}
			variant={variant}
			size={size}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			disabled={disabled}
			error={error}
			slotProps={{
				htmlInput: { maxLength: maxLength },
				inputLabel: {
					component: TextFieldInputLabel
				}
			}}
			{...(adornment
				? {
						InputProps: {
							...(adornmentPosition === 'start'
								? {
										startAdornment: (
											<InputAdornment position="start">{adornment}</InputAdornment>
										)
									}
								: {
										endAdornment: (
											<InputAdornment position="end">{adornment}</InputAdornment>
										)
									})
						}
					}
				: {})}
			sx={styles}
		/>
	)
})

OutlinedTextField.displayName = 'OutlinedTextField'
