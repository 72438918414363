import { tableFillSelectionFilter, URLS } from '@netcurio/frontend-common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	NetcurioAlert,
	NetcurioButton,
	NumericInputFilter,
	ResultNotFound,
	Severity,
	TagsFilter,
	TextInputFilter,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { InformationMessage } from '@netcurio/frontend-components/src'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { HeaderTable } from '../../../components/HeaderTable/HeaderTable'
import Constants from '../../../utilities/constants'
import listHelper from '../../../utilities/listHelper'
import { tableFillHeader } from '../../../utilities/tableFillHeader'
import styles from './invoicesList.module.scss'
import { InvoicesTableRow } from './InvoicesTableRow'
import { MainTableProps, TypesDataTable, TypesStyleMainTable } from './types'
import { dataTableInvoiceValue, stylesTableValue } from './utilities/defaultValues'
import {
	objectHeaderArray,
	objectPcFilterArray,
	objectStatusFilterArray
} from './utilities/tableParameterArray'

export const TableInvoices = ({
	dataInvoice,
	filteringInvoices,
	rowsActual,
	totalRows,
	userRoles = [],
	filterContainerBar,
	closeFilterContainerBar,
	dataFiltersArray,
	deleteFilterActive,
	deleteRange,
	filtersOfTypeStatus,
	filtersOfTypePaymentComp,
	setActivePagination,
	uuidInvoices,
	setUuidInvoices,
	handleUndoArchivedInvoices,
	handleUndoUnarchivedInvoices,
	undoArchivedCount,
	undoUnarchivedCount,
	showBarLoader,
	setShowBarLoader,
	alertType,
	alertText,
	openAlert
}: MainTableProps) => {
	const { t } = useTranslation()
	const { showLoadingSpinner } = useNetcurioLoader()
	const [dataTable, setDataTable] = useState<TypesDataTable>(dataTableInvoiceValue)
	const [styleMainTable, setStyleMainTable] = useState<TypesStyleMainTable>(stylesTableValue)
	const [fillFields, setFillFields] = useState<Array<tableFillHeader>>([])
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [addTransition, setAddTransition] = useState<boolean>(false)
	const [tempMessageAlert, setTempMessageAlert] = useState<number>()
	const showAlertRef = useRef<HTMLDivElement>(null)
	const isArchivedList: boolean = useLocation().pathname === URLS.INVOICE_LIST_ARCHIVED
	const heightRow = 40

	useEffect(() => {
		let finalHeight: number
		let mainHeight: number
		const isMediumWindow = 600
		const tableHeightCompensation = 106
		const containerHeightCompensation = 97
		const tableHeightCompensationSmall = 467
		const containerHeightCompensationSmall = 900

		if (window.innerHeight > isMediumWindow) {
			finalHeight = window.innerHeight - tableHeightCompensation
			mainHeight = window.innerHeight - containerHeightCompensation
		} else {
			finalHeight = tableHeightCompensationSmall
			mainHeight = containerHeightCompensationSmall
		}
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: finalHeight,
			mainContainerHeight: mainHeight
		}))
	}, [])

	useEffect(() => {
		if (userRoles && userRoles.length > 0) {
			setFillFields(objectHeaderArray(userRoles))
			setDataTable((prevState) => ({
				...prevState,
				fillStatusInputFilter: objectStatusFilterArray(userRoles),
				fillPcInputFilter: objectPcFilterArray()
			}))
		}
	}, [userRoles])

	useEffect(() => {
		if (deleteRange && dataTable.deleteRange) {
			if (dataTable.actualField === 'created_at' || dataTable.actualField === 'proposed_payment_date') {
				const isCreatedAt = dataTable.actualField === 'created_at'
				const isProposedPaymentDate = dataTable.actualField === 'proposed_payment_date'
				setDataTable((prevState) => ({
					...prevState,
					initRange: isCreatedAt ? undefined : prevState.initRange,
					finalRange: isCreatedAt ? undefined : prevState.finalRange,
					initPropRange: isProposedPaymentDate ? undefined : prevState.initPropRange,
					finalPropRange: isProposedPaymentDate ? undefined : prevState.finalPropRange,
					deleteRange: false
				}))
			} else if (dataTable.actualField === 'total') {
				setDataTable((prevState) => ({
					...prevState,
					minRange: undefined,
					maxRange: undefined,
					deleteRange: false
				}))
			}
		}
		if (filtersOfTypeStatus.status?.dataFilter === 'return') {
			if (
				userRoles &&
				dataTable.fillStatusInputFilter.length !== objectStatusFilterArray(userRoles).length
			) {
				setDataTable((prevState) => ({
					...prevState,
					fillStatusInputFilter: objectStatusFilterArray(userRoles)
				}))
			}
		} else if (filtersOfTypeStatus.status?.dataFilter !== '' && filtersOfTypeStatus.status?.dataFilter) {
			if (
				!dataTable.fillStatusInputFilter.some(
					(selectionFilter) =>
						selectionFilter.getSelectedParameter() === filtersOfTypeStatus.status?.dataFilter
				)
			) {
				setDataTable((prevState) => ({
					...prevState,
					fillStatusInputFilter: [
						...prevState.fillStatusInputFilter,
						new tableFillSelectionFilter(filtersOfTypeStatus.status?.dataFilter)
					]
				}))
			}
		}
		if (filtersOfTypePaymentComp.status?.dataFilter === 'return') {
			if (dataTable.fillPcInputFilter.length !== objectPcFilterArray().length) {
				setDataTable((prevState) => ({
					...prevState,
					fillPcInputFilter: objectPcFilterArray()
				}))
			}
		} else if (
			filtersOfTypePaymentComp.status?.dataFilter !== '' &&
			filtersOfTypePaymentComp.status?.dataFilter
		) {
			if (
				!dataTable.fillPcInputFilter.some(
					(selectionFilter) =>
						selectionFilter.getSelectedParameter() === filtersOfTypePaymentComp.status?.dataFilter
				)
			) {
				setDataTable((prevState) => ({
					...prevState,
					fillPcInputFilter: [
						...prevState.fillPcInputFilter,
						new tableFillSelectionFilter(filtersOfTypePaymentComp.status?.dataFilter)
					]
				}))
			}
		}
		setResultNotFound(totalRows < 1)
		listHelper.updateHeaderBottomRadius(styles.tableHeaderClicked)
	}, [
		deleteRange,
		dataTable.deleteRange,
		dataTable.actualField,
		filtersOfTypeStatus,
		filtersOfTypePaymentComp,
		dataTable.filterValue,
		filterContainerBar,
		totalRows,
		dataTable
	])

	useEffect(() => {
		if (filterContainerBar && styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				activeHeight: false,
				mainTableHeight: prevState.mainTableHeight - heightRow,
				mainContainerHeight: prevState.mainContainerHeight - heightRow
			}))
		} else if (!filterContainerBar && !styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				mainTableHeight: prevState.mainTableHeight + heightRow,
				activeHeight: true,
				mainContainerHeight: prevState.mainContainerHeight + heightRow
			}))
		}
	}, [filterContainerBar])

	useEffect(() => {
		if (undoArchivedCount > 0) {
			setTempMessageAlert(undoArchivedCount)
			setAddTransition(true)
		}
		if (undoUnarchivedCount > 0) {
			setTempMessageAlert(undoUnarchivedCount)
			setAddTransition(true)
		}
	}, [undoArchivedCount, undoUnarchivedCount])

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual: string = field + 'Date'
		const rangeActual: string = field + 'Range'
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', dataTable.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setDataTable((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const handleTextFilterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value = evt.target.value
		setDataTable((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}

	const handleTextFilterChangeQty = (evt: ChangeEvent<HTMLInputElement>, order: string) => {
		evt.preventDefault()
		let newFilterValue: string = evt.target.value
		const value: string = evt.target.value
		if (parseFloat(newFilterValue) < 0) {
			newFilterValue = undefined
		}
		if (order === 'initial') {
			setDataTable((prevState) => ({
				...prevState,
				minRange: newFilterValue,
				minAmount: value
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				maxRange: newFilterValue,
				maxAmount: value
			}))
		}
	}

	const selectionFilter = (status: string, position: number) => {
		if (dataTable.actualField === 'status' && filtersOfTypeStatus.status?.numSelectOptions < 7) {
			callDataList('', '', dataTable.actualField, status)
			dataTable.fillStatusInputFilter.splice(position, 1)
		}
		if (dataTable.actualField === 'payment_complement') {
			callDataList('', '', dataTable.actualField, status)
			dataTable.fillPcInputFilter.splice(position, 1)
		}
	}

	const emptyFilterField = () => {
		setDataTable((prevState) => ({
			...prevState,
			minAmount: '',
			maxAmount: '',
			finalDate: undefined,
			initDate: undefined,
			initPropDate: undefined,
			finalPropDate: undefined,
			filterValue: ''
		}))
	}

	const handleFilterKeyDown = (evt: KeyboardEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			dataTable.actualField,
			dataTable.filterValue,
			dataTable.minRange,
			dataTable.maxRange
		)
	}

	const handlePagination = () => {
		listHelper.handlePagination(
			styles.infoInvoices,
			totalRows,
			rowsActual,
			undefined,
			setActivePagination,
			setShowBarLoader
		)
	}

	const showFilters = (field: string, fieldType: string) => {
		if (field === 'statusDetail') {
			return
		}
		setAddTransition(false)
		if (field) {
			const filterActual = `${fieldType}Filter`
			let classActualField = 'display-none'
			let activeFilter

			switch (filterActual) {
				case 'numericFilter':
					classActualField = `invoices-${fieldType}-filter`
					activeFilter = dataTable.numericFilter
					break
				case 'proposedDateFilter':
					classActualField = `invoices-${fieldType}-filter`
					activeFilter = dataTable.proposedDateFilter
					break
				case 'statusFilter':
					classActualField = `invoices-${fieldType}-filter`
					activeFilter = dataTable.statusFilter
					break
				case 'textFilter':
					classActualField = 'display-block'
					activeFilter = dataTable.textFilter
					break
				case 'hasRelationFilter':
					classActualField = 'display-block'
					activeFilter = dataTable.hasRelationFilter
					break
			}
			if (activeFilter === 'display-none') {
				const verifyMainContainer: boolean =
					styleMainTable.mainTableHeight >
					window.innerHeight - (dataTable.actualField === field || filterContainerBar ? 155 : 115)
				if (verifyMainContainer) {
					setStyleMainTable((prevState) => ({
						...prevState,
						mainTableHeight: prevState.mainTableHeight - heightRow,
						mainContainerHeight: prevState.mainContainerHeight - heightRow
					}))
				}
				setDataTable((prevState) => ({
					...prevState,
					initRange: undefined,
					finalRange: undefined,
					initPropRange: undefined,
					finalPropRange: undefined,
					numericFilter: 'display-none',
					dateFilter: 'display-none',
					proposedDateFilter: 'display-none',
					statusFilter: 'display-none',
					textFilter: 'display-none',
					hasRelationFilter: 'display-none',
					initDate: undefined,
					finalDate: undefined,
					initPropDate: undefined,
					finalPropDate: undefined,
					minAmount: undefined,
					maxAmount: undefined,
					minRange: undefined,
					maxRange: undefined,
					filterValue: '',
					actualField: field,
					textHeader: styles.textHeaderListOnClick,
					initDateErrorText: '',
					finalDateErrorText: '',
					initPropDateErrorText: '',
					finalPropDateErrorText: '',
					initPropDateStyleErrorText: '',
					finalPropDateStyleErrorText: '',
					[filterActual]: classActualField
				}))
			} else if (dataTable.actualField === field) {
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight + heightRow,
					mainContainerHeight: prevState.mainContainerHeight + heightRow
				}))
				setDataTable((prevState) => ({
					...prevState,
					filterValue: '',
					textHeader: styles.textHeaderListInvoices,
					initRange: undefined,
					finalRange: undefined,
					initPropRange: undefined,
					finalPropRange: undefined,
					[filterActual]: 'display-none'
				}))
			} else {
				setDataTable((prevState) => ({
					...prevState,
					actualField: field,
					filterValue: ''
				}))
			}
			listHelper.updateHeaderColors(fillFields, field, styles.tableHeaderClicked)
		}
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValue: string,
		objectRange?: { [key: string]: number | Dayjs }
	) => {
		showLoadingSpinner()
		if (sortField !== '') {
			filteringInvoices(0, sortField, order, fieldFilter, undefined, undefined, undefined, undefined)
		} else {
			let initRange
			let finalRange
			switch (fieldFilter) {
				case 'created_at':
					initRange = dataTable.initRange
					finalRange = dataTable.finalRange
					if (objectRange) {
						if (objectRange.initRange) initRange = objectRange.initRange
						if (objectRange.finalRange) finalRange = objectRange.finalRange
					}
					break
				case 'proposed_payment_date':
					initRange = dataTable.initPropRange
					finalRange = dataTable.finalPropRange
					if (objectRange) {
						if (objectRange.initPropRange) initRange = objectRange.initPropRange
						if (objectRange.finalPropRange) finalRange = objectRange.finalPropRange
					}
					break
				case 'total':
					if (dataTable.minRange) initRange = dataTable.minRange
					if (dataTable.maxRange) finalRange = dataTable.maxRange
					break
			}
			filteringInvoices(0, sortField, order, fieldFilter, filterValue, initRange, finalRange, undefined)
		}
		setDataTable((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const closeFilter = () => {
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + heightRow,
			mainContainerHeight: prevState.mainContainerHeight + heightRow
		}))

		setDataTable((prevState) => ({
			...prevState,
			numericFilter: 'display-none',
			dateFilter: 'display-none',
			proposedDateFilter: 'display-none',
			statusFilter: 'display-none',
			textFilter: 'display-none',
			hasRelationFilter: 'display-none',
			headerMainTableInvoicesClick: styles.tableHeaderClicked,
			initRange: undefined,
			finalRange: undefined,
			initPropRange: undefined,
			finalPropRange: undefined,
			textHeader: styles.tableHeaderClicked,
			filterValue: undefined
		}))
	}

	return (
		<>
			<div className={styles.headerMainTableInvoices}>
				<div className={styles.headerForGridAreaPinSection}>
					<div
						className={classNames(
							dataTable.textHeader,
							styles.alignCounter,
							styles.textHeaderOrderListFoGridArea,
							styles.tableHeaderLeftRadius
						)}
					>
						<p
							className={
								dataTable.textHeader === styles.textHeaderListInvoices
									? styles.textCounter
									: styles.textCounterActive
							}
						>
							{uuidInvoices?.size === 0 ? null : `(${uuidInvoices?.size})`}
						</p>
					</div>
				</div>
				{Object.keys(fillFields).map((key: string) => (
					<HeaderTable
						key={key}
						textHeader={dataTable.textHeader}
						showFilters={showFilters}
						dataMainHeaderTable={fillFields[parseInt(key)]}
						isForGridArea
					/>
				))}
			</div>
			<TextInputFilter
				showTextFilter={dataTable.textFilter}
				actualField={dataTable.actualField}
				valueFilter={dataTable.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			<TagsFilter
				showTagsFilter={dataTable.statusFilter}
				actualField={dataTable.actualField}
				callDataList={callDataList}
				fillInputFilter={dataTable.fillStatusInputFilter}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
			/>
			<TagsFilter
				showTagsFilter={dataTable.hasRelationFilter}
				actualField={dataTable.actualField}
				callDataList={callDataList}
				fillInputFilter={dataTable.fillPcInputFilter}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
				concatBeforeTranslation={'invoices.'}
			/>
			<DateInputFilter
				showDateFilter={dataTable.proposedDateFilter}
				actualField={dataTable.actualField}
				initDate={dataTable.initPropDate}
				finalDate={dataTable.finalPropDate}
				initReferenceText="initProp"
				finReferenceText="finalProp"
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			<NumericInputFilter
				showNumFilter={dataTable.numericFilter}
				actualField={dataTable.actualField}
				minAmount={dataTable.minAmount}
				maxAmount={dataTable.maxAmount}
				handleTextFilterChangeQty={handleTextFilterChangeQty}
				handleFilterKeyDown={handleFilterKeyDown}
				callDataList={callDataList}
				closeFilter={closeFilter}
			/>
			{filterContainerBar && (
				<ActiveFiltersBar
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					objectType={Constants.LISTS.IN}
				/>
			)}
			<InformationMessage
				open={openAlert}
				messageType={alertType ?? Severity.Info}
				message={alertText ?? ''}
			/>
			<div
				ref={showAlertRef}
				className={styles.transitionHeightAlert}
				style={{
					height: undoArchivedCount > 0 ? (showAlertRef?.current?.scrollHeight ?? 0) : 0
				}}
			>
				<NetcurioAlert severity={Severity.Info} messagePadding="0">
					<div className={styles.undoAlert}>
						<span>{t('invoicesArchived', { count: tempMessageAlert })}</span>
						<NetcurioButton
							disabled={undoArchivedCount === 0}
							sx={{ color: Constants.COLORS.BLUE_DARK }}
							onClick={handleUndoArchivedInvoices}
						>
							{t('undo')}
						</NetcurioButton>
					</div>
				</NetcurioAlert>
			</div>
			<div
				ref={showAlertRef}
				className={styles.transitionHeightAlert}
				style={{
					height: undoUnarchivedCount > 0 ? (showAlertRef?.current?.scrollHeight ?? 0) : 0
				}}
			>
				<NetcurioAlert severity={Severity.Info} messagePadding="0">
					<div className={styles.undoAlert}>
						<span>{t('invoiceUnarchived', { count: tempMessageAlert })}</span>
						<NetcurioButton
							disabled={undoUnarchivedCount === 0}
							sx={{ color: Constants.COLORS.BLUE_DARK }}
							onClick={handleUndoUnarchivedInvoices}
						>
							{t('undo')}
						</NetcurioButton>
					</div>
				</NetcurioAlert>
			</div>
			<div
				className={classNames(
					styles.tableInformationWhiteStyle,
					addTransition && styles.transitionHeightAlert
				)}
				style={{
					height:
						styleMainTable.mainContainerHeight -
						(undoArchivedCount > 0 || undoUnarchivedCount > 0
							? (showAlertRef?.current?.scrollHeight ?? 0)
							: 0)
				}}
			>
				<div
					className={styles.infoInvoices}
					style={{
						height:
							styleMainTable.mainTableHeight -
							(undoArchivedCount > 0 || undoUnarchivedCount > 0
								? (showAlertRef?.current?.scrollHeight ?? 0)
								: 0)
					}}
					onScroll={handlePagination}
				>
					<ResultNotFound
						showNotFound={resultNotFound}
						bodyText={
							isArchivedList && dataFiltersArray.length === 0
								? t('noInvoicesArchived')
								: t('resultNotFound')
						}
					/>
					{Object.keys(dataInvoice).map((key: string) => (
						<InvoicesTableRow
							key={key}
							informationTable={dataInvoice[parseInt(key)]}
							userRoles={userRoles}
							uuidInvoices={uuidInvoices}
							setUuidInvoices={setUuidInvoices}
						/>
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
		</>
	)
}
