import { PurchaseRequisitionStatusEnum } from '../../enums/purchase-requisition-status.enum'
import { PurchaseRequisitionItemInterface } from '../../interfaces/purchase-requisition-item.interface'
import { PurchaseRequisitionInterface } from '../../interfaces/purchase-requisition.interface'

export const defaultValuesItemPr: PurchaseRequisitionItemInterface = {
	position: 1,
	customerRfc: '',
	supplierRfc: '',
	code: '',
	description: '',
	quantity: 0,
	unitPrice: 0,
	unit: '',
	netAmount: 0,
	requiredDeliveryDate: new Date()
}

export const defaultValuesPurchaseRequisition: PurchaseRequisitionInterface = {
	customerRfc: '',
	requester: { email: '' },
	requesterEmail: '',
	createdBy: { email: '' },
	type: '',
	status: PurchaseRequisitionStatusEnum.NEW,
	description: '',
	branchOfficeId: '',
	branchOffice: '',
	deliveryAddressLine1: '',
	deliveryAddressLine2: '',
	deliveryAddressPostalCode: '',
	deliveryAddressStateCode: '',
	deliveryAddressState: '',
	deliveryAddressCountryCode: '',
	deliveryAddressCountry: '',
	currency: '',
	areaId: '',
	items: [defaultValuesItemPr]
}
